const getAPIUrl = (env) => {    
    return process.env.REACT_APP_API_URL;
}

const getDataPromise = (url, jwt) => {
    let api_url = getAPIUrl(process.env.NODE_ENV);
    return fetch(`${api_url}${url}`, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',        
        headers: {
            'Content-Type': 'application/json',
            'Authorization': jwt,
        },
    });
}

const postDataPromise = async (url, data = {}, jwt) => {
    let api_url = getAPIUrl(process.env.NODE_ENV);
    return fetch(`${api_url}${url}`, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',        
        headers: {
            'Content-Type': 'application/json',
            'Authorization': jwt,
        },
        body: JSON.stringify(data)
    });
}

const deleteDataPromise = (url, data = {}, jwt) => {
    let api_url = getAPIUrl(process.env.NODE_ENV);
    return fetch(`${api_url}${url}`, {
        method: 'DELETE',
        mode: 'cors',
        cache: 'no-cache',        
        headers: {
            'Content-Type': 'application/json',
            'Authorization': jwt,
        },
        body: JSON.stringify(data)
    });
}

const getPdfPromise = async (url, jwt) => {
    let api_url = getAPIUrl(process.env.NODE_ENV);
    return fetch(`${api_url}${url}`, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',       
        headers: {
            'Content-Type': 'application/pdf',
            'Authorization': jwt,
        },
    });
}

export const getRunData = (numflows, jwt) => {   
    return numflows !== undefined ? getDataPromise(`/nexus/rundataallref/${numflows}`, jwt) : getDataPromise(`/nexus/rundataallref/`, jwt);
};

export const getRunDataGrouped = (numflows, jwt) => {
    return numflows !== undefined ? getDataPromise(`/nexus/rundataallgrouped/${numflows}`, jwt) : getDataPromise(`/nexus/rundataallgrouped/`, jwt);
};

export const getRunDataByRowCount = (rowCount, jwt) => {
    return getDataPromise(`/nexus/rundata/rows/${rowCount}`, jwt);
};

export const getRunDataByDate = (start_date, end_date, jwt) => {
    let start_date_str;
    let end_date_str;
    if(start_date.includes('/')){
        start_date_str = start_date?.replace(/\//g, '-');
        end_date_str = end_date?.replace(/\//g, '-');
    } else {
        start_date_str = start_date?.split(',')[0].replace(/\//g, '-');
        end_date_str = end_date?.split(',')[0].replace(/\//g, '-');
    }
    return getDataPromise(`/nexus/bydate/rundata/${start_date_str}/${end_date_str}`, jwt);
};

export const getRunDataByRunId = (runid, jwt) => {
    return getDataPromise(`/nexus/rundata/${runid}`, jwt);
};

export const getAMPData = (jwt) => {
    return getDataPromise(`/nexus/ampdata`, jwt);
};

export const getAmpRunCSV = (runid, jwt) => {
    return getDataPromise(`/nexus/amprun/${runid}`, jwt);
}

export const getAMPPlotsData = (runid, jwt) => {
    return getDataPromise(`/nexus/ampplots/${runid}`, jwt);
};

export const getSignalPlotsData = (runid, jwt) => {
    return getDataPromise(`/nexus/signalplotsdata/${runid}`, jwt);
};

export const getHistogramPlotsData = (runid, flow, jwt) => {
    console.log(`/nexus/histogramplotsdata/${runid}/${flow}`)
    return getDataPromise(`/nexus/histogramplotsdata/${runid}/${flow}`, jwt);
};

export const getHistogramPlotsDataAll = (runid, flow, jwt) => {
    return getDataPromise(`/nexus/histogramplotsdata/all/${runid}/${flow}`, jwt);
};

export const getHistogramPlotsByFlowData = (runid, flowarr, jwt) => {
    return getDataPromise(`/nexus/histogramplotsdataflows/${runid}/${flowarr}`, jwt);
};

export const getTileAreaAsync = (runId, instrId, jwt) => {
    return getDataPromise(`/nexus/tilearea/${runId}/${instrId}`, jwt);
};

export const getCirclePlotsData = (runid, flow, jwt) => {
    return getDataPromise(`/nexus/circleplotsdata/${runid}/${flow}`, jwt);
};

export const getRunConfigurationTableData = (runid, flow, jwt) => {
    return getDataPromise(`/nexus/csv/rundata/${runid}/${flow}`, jwt);
};

export const getPipelineVersionData = (runid, flow, jwt) => {
    return getDataPromise(`/nexus/csv/pipelineversion/${runid}/${flow}`, jwt);
};

export const getGitRevsData = (runid, flow, jwt) => {
    return getDataPromise(`/nexus/csv/gitrevs/${runid}/${flow}`, jwt);
};

export const getPNGData = (runid, imgName, isRunBarcoded, jwt) => {
    return getDataPromise(`/nexus/png/${runid}/${imgName}/${isRunBarcoded}`, jwt);
};

export const checkRunIsBarcodedAsync = (runid, jwt) => {
    return getDataPromise(`/nexus/isbarcoded/${runid}`, jwt);
};

export const getBeadsFilteringTableDataAsync = (runid, isRunBarcoded, jwt) => {
    return getDataPromise(`/nexus/csv/beadsfiltering/${runid}/${isRunBarcoded}`, jwt);
};

export const getBarcodeStatisticsTableDataAsync = (runid, jwt) => {
    return getDataPromise(`/nexus/csv/barcodestatistics/${runid}`, jwt);
};

export const getBERTableDataAsync = (runid, jwt) => {
    return getDataPromise(`/nexus/csv/ber/${runid}`, jwt);
};

export const getBERHMERTableDataAsync = (runid, jwt) => {
    return getDataPromise(`/nexus/csv/hmer/${runid}`, jwt);
};

export const getRLQTableDataAsync = (runid, jwt) => {
    return getDataPromise(`/nexus/csv/rlq/${runid}`, jwt);
};

export const getFMetricsTableDataAsync = (runid, isRunBarcoded, jwt) => {
    return getDataPromise(`/nexus/csv/fmetrics/${runid}/${isRunBarcoded}`, jwt);
};

export const getJSONData = (runid, imgName, isRunBarcoded, jwt) => {
    return getDataPromise(`/nexus/json/${runid}/${imgName}/${isRunBarcoded}`, jwt);
};

export const getQTableDataAsync = (runid, jwt) => {
    return getDataPromise(`/nexus/csv/qtable/${runid}`, jwt);
};

export const getBaseCallingReportAsync = (runid, type, jwt) => {
    return getDataPromise(`/nexus/basecalling/${runid}/${type}`, jwt);
};

export const checkBaseCallingReportExistAsync = (runid, type, jwt) => {
    return getDataPromise(`/nexus/basecalling/check/${runid}/${type}`, jwt);
};

export const getSAMReportAsync = (runid, jwt) => {
    return getDataPromise(`/nexus/sam/${runid}`, jwt);
};

export const getSignalsLinearitTableDataAsync = (runid, isRunBarcoded, jwt) => {
    return getDataPromise(`/nexus/csv/linearity/${runid}/${isRunBarcoded}`, jwt);
};

export const getOpticsPlotsData = (runid, flow, type, jwt) => {
    return getDataPromise(`/nexus/opticsplotsdata/${runid}/${flow}/${type}`, jwt);
};

export const getOpticsPlotsLaserData = (runid, jwt) => {
    return getDataPromise(`/nexus/opticsplotslaserdata/${runid}`, jwt);
};

export const getClumpingsTableDataAsync = (runid, jwt) => {
    return getDataPromise(`/nexus/csv/clumpings/${runid}`, jwt);
};

export const getPdfAsync = (jwt) => {
    return getPdfPromise(`/nexus/pdf`, jwt);
};

export const getHealthPlotsTemperatureDataAsync = (runid, jwt) => {
    return getDataPromise(`/nexus/healthdata/${runid}/tmpr`, jwt);
};

export const getHealthPlotsHumidityDataAsync = (runid, jwt) => {
    return getDataPromise(`/nexus/healthdata/${runid}/humidity`, jwt);
};

export const getPhasingTableDataAsync = (runid, jwt) => {
    return getDataPromise(`/nexus/csv/phasing/${runid}`, jwt);
};

export const getCovGCBiasTableDataAsync = (runid, jwt) => {
    return getDataPromise(`/nexus/csv/covgcbias/${runid}`, jwt);
};

export const checkVCReportExistsAsync = (runid, jwt) => {
    return getDataPromise(`/nexus/vcreport/${runid}`, jwt);
};

export const getImgDeformData = (runid, jwt) => {
    return getDataPromise(`/nexus/imgdeform/${runid}`, jwt);
};

export const getBeadLossData = (runid, jwt) => {
    return getDataPromise(`/nexus/beadloss/${runid}`, jwt);
};

export const getFWHMData = (runid, jwt) => {
    return getDataPromise(`/nexus/fwhm/${runid}`, jwt);
};

export const getFWHMDataMany = (runids, jwt) => {
    return postDataPromise(`/nexus/fwhm`, runids, jwt);
};

export const getTemplateReport = (runid, jwt) => {
    return getPdfPromise(`/nexus/template/${runid}`, jwt);
};

export const checkTemplateReportExist = (runid, jwt) => {
    return getDataPromise(`/nexus/template/check/${runid}`, jwt);
};

export const getSupplementReport = (runid, jwt) => {
    return getPdfPromise(`/nexus/suppl/${runid}`, jwt);
};

export const getLateFlowMetricsData = (runid, jwt) => {
    return getPdfPromise(`/nexus/lfm/${runid}`, jwt);
};

export const getNoiseAndContextData = (runid, jwt) => {
    return getPdfPromise(`/nexus/noise/${runid}`, jwt);
};

export const postAnalysisCommentData = (runid, data, jwt) => {
    return postDataPromise(`/nexus/analysiscomments/${runid}`, data, jwt);
};

export const postDetailsData = (runid, data, jwt) => {
    return postDataPromise(`/nexus/details/${runid}`, data, jwt);
};

export const getH5BerPerFlowPlotData = (runid, key, camera, jwt) => {
    let params = {
        "key": key,
    };
    let query = Object.keys(params).map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k])).join('&');
    let url = `/nexus/h5/berperflow/${runid}/${camera}?${query}`;
    return getDataPromise(url, jwt);
};

export const getH5BerPerReadHistPlotData = (runid, key, camera, jwt) => {
    let params = {
        "key": key,
    };
    let query = Object.keys(params).map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k])).join('&');
    let url = `/nexus/h5/berperhist/${runid}/${camera}?${query}`;
    return getDataPromise(url, jwt);
};

export const getAnalysisMetaDataAsync = (runid, jwt) => {
    return getDataPromise(`/nexus/analysismetadata/${runid}`, jwt);
};

export const getVersionsAsync = (runid, jwt) => {
    return getDataPromise(`/nexus/versions/${runid}`, jwt);
};

export const getH5ReadLengthPlotData = (runid, key, camera, jwt) => {
    let params = {
        "key": key,
    };
    let query = Object.keys(params).map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k])).join('&');
    let url = `/nexus/h5/rl/${runid}/${camera}?${query}`;
    return getDataPromise(url, jwt);
};

export const getH5MedianRSQPlotData = (runid, key, camera, jwt) => {
    let params = {
        "key": key,
    };
    let query = Object.keys(params).map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k])).join('&');
    let url = `/nexus/h5/rsq/${runid}/${camera}?${query}`;
    return getDataPromise(url, jwt);
};

export const getBCALLJSONData = (runid, imgName, isRunBarcoded, jwt) => {
    return getDataPromise(`/nexus/bcall/${runid}/${imgName}/${isRunBarcoded}`, jwt);
};

export const getReadsPassFilterAsync = (runid, jwt) => {
    return getDataPromise(`/nexus/rpf/${runid}`, jwt);
};

export const getH5ReadsFilteringPlotData = (runid, key, camera, jwt) => {
    let params = {
        "key": key,
    };
    let query = Object.keys(params).map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k])).join('&');
    let url = `/nexus/h5/rf/${runid}/${camera}?${query}`;
    return getDataPromise(url, jwt);
};

export const getHomoIndelErrorAsync = (runid, jwt) => {
    return getDataPromise(`/nexus/homoindelerror/${runid}`, jwt);
};

export const getH5FlowErrorPlotData = (runid, key, camera, jwt) => {
    let params = {
        "key": key,
    };
    let query = Object.keys(params).map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k])).join('&');
    let url = `/nexus/h5/flowerror/${runid}/${camera}?${query}`;
    return getDataPromise(url, jwt);
};

export const getBarcodeDetailsReadLengthPlotAsync = (runid, jwt) => {
    return getDataPromise(`/nexus/bdrl/${runid}`, jwt);
};

export const getH5PolyPlotDataAsync = (runid, camera, jwt) => {   
    let url = `/nexus/h5/poly/${runid}/${camera}`;
    return getDataPromise(url, jwt);
}

export const getFWHMCSVDataAsync = (runid, camera, jwt) => {
    return getDataPromise(`/nexus/fwhmcsv/${runid}/${camera}`, jwt);
};

export const getRunValidatedDataByRunId = (runid, jwt) => {
    return getDataPromise(`/ivt/nexus/rundata/${runid}`, jwt);
};

export const getRunGroupedAllValidated = (numflows, jwt) => {
    return numflows !== undefined ? getDataPromise(`/ivt/nexus/rundataallgrouped/${numflows}`, jwt) : getDataPromise(`/ivt/rundataallgrouped/`, jwt);
}

export const getAMPValidatedData = (jwt) => {
    return getDataPromise(`/ivt/nexus/ampdata`, jwt);
};

export const getH5PolyPlotDataValidatedAsync = (runid, camera, jwt) => {
    return getDataPromise(`/ivt/nexus/h5/poly/${runid}/${camera}`, jwt);
};

export const getFwhmDataValidatedAsync = (runid, jwt) => {
    return getDataPromise(`/ivt/nexus/fwhm/${runid}`, jwt);
};

export const getH5FlowErrorPlotDataValidated = (runid, key, camera, jwt) => {
    let params = {
        "key": key,
    };
    let query = Object.keys(params).map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k])).join('&');
    let url = `/ivt/nexus/h5/flowerror/${runid}/${camera}?${query}`;
    return getDataPromise(url, jwt);
};

export const getRunDataAsyncValidated = (runid, flow, jwt) => {
    return getDataPromise(`/ivt/nexus/csv/rundata/${runid}/${flow}`, jwt);
};

export const getPipeLineDataAsyncValidated = (runid, flow, jwt) => {
    return getDataPromise(`/ivt/nexus/csv/pipelineversion/${runid}/${flow}`, jwt);
};

export const getGitRevDataAsyncValidated = (runid, flow, jwt) => {
    return getDataPromise(`/ivt/nexus/csv/gitrevs/${runid}/${flow}`, jwt);
};

export const getAnalysisMetaDataAsyncValidated = (runid, jwt) => {
    return getDataPromise(`/ivt/nexus/csv/analysismetadata/${runid}`, jwt);
};

export const getVersionsDataAsyncValidated = (runid, jwt) => {
    return getDataPromise(`/ivt/nexus/csv/versions/${runid}`, jwt);
};

export const getReadPeadsFilteringDataAsyncValidated = (runid, jwt) => {
    return getDataPromise(`/ivt/nexus/csv/rpf/${runid}`, jwt);
};

export const getH5BerFlowPlotDataValidated = (runid, key, camera, jwt) => {
    let params = {
        "key": key,
    };
    let query = Object.keys(params).map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k])).join('&');
    let url = `/ivt/nexus/h5/berperflow/${runid}/${camera}?${query}`;
    return getDataPromise(url, jwt);
};

export const getH5BerHistPlotDataValidated = (runid, key, camera, jwt) => {
    let params = {
        "key": key,
    };
    let query = Object.keys(params).map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k])).join('&');
    let url = `/ivt/nexus/h5/berperhist/${runid}/${camera}?${query}`;
    return getDataPromise(url, jwt);
};

export const getH5ReadLengthPlotDataValidated = (runid, key, camera, jwt) => {
    let params = {
        "key": key,
    };
    let query = Object.keys(params).map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k])).join('&');
    let url = `/ivt/nexus/h5/rl/${runid}/${camera}?${query}`;
    return getDataPromise(url, jwt);
};

export const getPhasingDataAsyncValidated = (runid, jwt) => {
    return getDataPromise(`/ivt/nexus/csv/phasing/${runid}`, jwt);
};

export const getH5ReadsFilteringPlotDataValidated = (runid, key, camera, jwt) => {
    let params = {
        "key": key,
    };
    let query = Object.keys(params).map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k])).join('&');
    let url = `/ivt/nexus/h5/rf/${runid}/${camera}?${query}`;
    return getDataPromise(url, jwt);
};

export const getH5MedianRSQPlotDataValidated = (runid, key, camera, jwt) => {
    let params = {
        "key": key,
    };
    let query = Object.keys(params).map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k])).join('&');
    let url = `/ivt/nexus/h5/rsq/${runid}/${camera}?${query}`;
    return getDataPromise(url, jwt);
};

export const getBarcodeStatisticsTableDataAsyncValidated = (runid, jwt) => {
    return getDataPromise(`/ivt/nexus/csv/barcodestatistics/${runid}`, jwt);
};

export const getClumpingDataAsyncValidated = (runid, jwt) => {
    return getDataPromise(`/ivt/nexus/csv/clumpings/${runid}`, jwt);
};

export const getLinearityDataAsyncValidated = (runid, isrunbarcoded, jwt) => {
    return getDataPromise(`/ivt/nexus/csv/linearity/${runid}/${isrunbarcoded}`, jwt);
};

export const getBeadsFilteringDataAsyncValidated = (runid, isrunbarcoded, jwt) => {
    return getDataPromise(`/ivt/nexus/csv/beadsfiltering/${runid}/${isrunbarcoded}`, jwt);
};

export const getBerDataAsyncValidated = (runid, jwt) => {
    return getDataPromise(`/ivt/nexus/csv/ber/${runid}`, jwt);
};

export const getHmerDataAsyncValidated = (runid, jwt) => {
    return getDataPromise(`/ivt/nexus/csv/hmer/${runid}`, jwt);
};

export const getRLQDataAsyncValidated = (runid, jwt) => {
    return getDataPromise(`/ivt/nexus/csv/rlq/${runid}`, jwt);
};

export const getFMetricsDataAsyncValidated = (runid, isRunBarcoded, jwt) => {
    return getDataPromise(`/ivt/nexus/csv/fmetrics/${runid}/${isRunBarcoded}`, jwt);
};

export const getQTableDataAsyncValidated = (runid, jwt) => {
    return getDataPromise(`/ivt/nexus/csv/qtable/${runid}`, jwt);
}
export const getRunInfoDataAsync = (jwt) => {
    return getDataPromise('/nexus/storageManagement/runinfo', jwt);
};

export const getDiskSpaceDataAsync = (jwt) => {
    return getDataPromise('/nexus/storageManagement/diskspace', jwt);
};

export const deleteRunsApplyDataAsync = (data, jwt) => {
    return postDataPromise('/nexus/storageManagement/deleteRun/apply', data, jwt);
};

export const getCalculatedFreeSpaceeDataAsync = (data, jwt) => {
    return postDataPromise('/nexus/storageManagement/calculateSpace', data, jwt);
};

export const getRunIdDataByDate = (start_date, end_date, jwt) => {
    let start_date_str;
    let end_date_str;
    if(start_date.includes('/')){
        start_date_str = start_date?.replace(/\//g, '-');
        end_date_str = end_date?.replace(/\//g, '-');
    } else {
        start_date_str = start_date?.split(',')[0].replace(/\//g, '-');
        end_date_str = end_date?.split(',')[0].replace(/\//g, '-');
    }
    return getDataPromise(`/nexus/runidlist/${start_date_str}/${end_date_str}`, jwt);
};

export const deleteRunIdsDataAsync = (data, jwt) => {
    return deleteDataPromise('/nexus/deleterunidlist', data, jwt);
};

export const getSignalPlotsDataAsyncValidated = (runid, jwt) => {
    return getDataPromise(`/ivt/nexus/signalplotsdata/${runid}`, jwt);
}

export const getPpmSeqReportListDataAsync = (runid, barcode, jwt) => {
    if (barcode?.length > 1) {
        // If there are multiple barcode, join them with commas and use as a query parameter
        const barcodeString = barcode.join(',');
        const url = `/nexus/checkPpmSeqH5/${runid}?barcodes=${barcodeString}`;
        return getDataPromise(url, jwt);
    } else {
        const url = `/nexus/checkPpmSeqH5/${runid}/${barcode[0]}`;
        return getDataPromise(url, jwt);
    }
};

export const getDeletionStatusAsync = (jobId, jwt) => {
    return getDataPromise(`/nexus/storageManagement/deletionStatus/${jobId}`, jwt);
};
