import React, {useState, useEffect} from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from "react-redux";
import { setCustomStorageValues, deleteRunsApply, getRunInfoData, getDeleteStatusByJobId, getDiskSpacData, calculcateStorageFreeSpace, setAlertStorageRun, getRunIdDataAllByDateAsync, deleteRunIdListData, setPopulateRuns } from "../actions/index";
import { DELETE_RUN_REQUEST_STATUS } from '../constants/action-types';
import Stack from '@mui/material/Stack';
import {
    Typography,
    IconButton,
    Button,
    OutlinedInput,
    Dialog,
    DialogTitle, 
    DialogContent, 
    DialogActions,
    Tabs,
    Tab,
    Box,
    TextField,
    Grid,
    FormControl, 
    MenuItem,
    InputLabel,
    Select,
    TextareaAutosize
  } from '@material-ui/core';
import PopupMessage from '../popUp/popUpMessage';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Col, Row } from 'react-bootstrap';
//import { TextareaAutosize } from '@mui/base/TextareaAutosize';

import * as Utils from '../../utils/utils';

const Storage = (props) => {
    const { handleClose, isOpen, triggerInterval, disableStorageActions } = props;
    
    let date = new Date();
    let prev3M = new Date(date.getFullYear(), date.getMonth() - 3, date.getDate());

    const custom_storage = useSelector(state => state.custom_storage);   
    const jwt = useSelector(state => state.jwt);   
    const delete_run_request_status = useSelector(state => state.delete_run_request_status);   
    const calculated_storage_free_space = useSelector(state => state.calculated_storage_free_space);   
    const diskspace = useSelector(state => state.diskspace);   
    const run_info = useSelector(state => state.run_info);   
    const is_import_field_loading = useSelector(state => state.is_import_field_loading);   
    const populate_runids = useSelector(state => state.populate_runids);   
    const dispatch = useDispatch();
    const [values, setValues] = useState({
        alertRun: custom_storage.alertRun,
        allRuns: custom_storage.allRuns,
        baseCallingQuality: custom_storage.baseCallingQuality,
        signalQualityWafer:custom_storage.signalQualityWafer,
        systemDebug: custom_storage.systemDebug,
        ampRuns: custom_storage.ampRuns,
    });
    const [message, setMessage] = useState('');
    const [runInfoCount, setRunInfoCount] = useState(undefined);
    const [diskSpace, setDiskSpace] = useState(undefined);
    const [lastCleanupDate, setLastCleanupDate] = useState(localStorage.getItem('lastCleanupDate'));
    const [calcFreeSpace, setCalcFreeSpace] = useState(undefined);
    const [PopUpOpen, setPopUpOpen] = useState(false);
    const [confirmationMessage, setConfirmationMessage] = useState(false);
    const [deleteStatus, setDeleteStatus] = useState(false);
    const [storedValues, setStoredValues] = useState({});
    const [tabValue, setTabValue] = useState(0);
    const [runIds, setRunIds] = useState('');
    const [selectedRunIds, setSelectedRunIds] = useState([]);
    const [dateFrom, setDateFrom] = React.useState(prev3M);
    const [dateTo, setDateTo] = React.useState(date);   
    const [runIdsPopulated, setRunIdsPopulated] = useState(false);

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };
  
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setValues({ ...values, [name]: parseInt(value) });
    };

    const handleDateFromChange = value => {
        setDateFrom(value);
    }

    const handleDateToChange = value => {
        setDateTo(value);
    }


    const handleImportRunIdsClick = () => {
        // Logic to import runIds based on selected date interval
        if (dateFrom !== undefined && dateTo !== undefined) {
            dispatch(getRunIdDataAllByDateAsync(new Date(dateFrom).toLocaleDateString("en-US"), new Date(dateTo).toLocaleDateString("en-US"), jwt));
        }
        if (populate_runids.length > 0) {
            // Extract runid_raw values from setSelectedRunIds array
            const runIdsArray = populate_runids.map(item => item.runid);
            // Join the runIdsArray with comma and set it to runIds state
            setRunIds(runIdsArray.join(', '));
        }
    };
    
    const handleDefaultClick = () => {
        setMessage('');
        setConfirmationMessage(false);
        try {
            const defaultValues = {
                alertRun: 50,
                allRuns: 18,
                baseCallingQuality: 6,
                signalQualityWafer: 6,
                systemDebug: 3,
                ampRuns: 3
            };
            setValues(defaultValues);
            dispatch(setCustomStorageValues(defaultValues));
            const stringify = JSON.stringify(defaultValues);
            dispatch(calculcateStorageFreeSpace(stringify, jwt));
            if (diskspace < defaultValues.alertRun) {
                dispatch(setAlertStorageRun(true));
            } else {
                dispatch(setAlertStorageRun(false));
            }
        } catch (error) {
            console.log(error)
        }
    }

    const handleApplyClick = () => {
        setMessage('');
        setConfirmationMessage(false);
        try {
            const updatedSettings = {
                alertRun: parseInt(values.alertRun),
                allRuns: parseInt(values.allRuns),
                baseCallingQuality: parseInt(values.baseCallingQuality),
                signalQualityWafer: parseInt(values.signalQualityWafer),
                systemDebug: parseInt(values.systemDebug),
                ampRuns: parseInt(values.ampRuns),
            };
    
            setValues(updatedSettings);
            dispatch(setCustomStorageValues(updatedSettings));
            
            const stringify = JSON.stringify(values);
            dispatch(calculcateStorageFreeSpace(stringify, jwt));
            if (diskspace < updatedSettings.alertRun) {
                dispatch(setAlertStorageRun(true));
            } else {
                dispatch(setAlertStorageRun(false));
            }
        } catch (error) {
            console.log(error)
        }
    }

    const handleDeleteClick = async () => {
        try {
            if(tabValue === 0){
                const updatedSettings = {
                    alertRun: parseInt(values.alertRun),
                    allRuns: parseInt(values.allRuns),
                    baseCallingQuality: parseInt(values.baseCallingQuality),
                    signalQualityWafer: parseInt(values.signalQualityWafer),
                    systemDebug: parseInt(values.systemDebug),
                    ampRuns: parseInt(values.ampRuns),
                };
        
                const currentDate = new Date().toLocaleString();
                setLastCleanupDate(currentDate);
                localStorage.setItem('lastCleanupDate', currentDate);
        
                setConfirmationMessage(true);
                setMessage('Data for the specified period will be permanently deleted. Press OK to start deleting or Cancel.');
                setValues(updatedSettings)
                dispatch(setCustomStorageValues(updatedSettings));
                setPopUpOpen(true);
            } else {
                if(runIds.length > 0){
                    setConfirmationMessage(true);
                    setMessage('Run Ids shown will be permanently deleted. Press OK to start deleting or Cancel.');
                    setPopUpOpen(true);
                } else {
                    setConfirmationMessage(false);
                    setMessage('Run Id field is empty.');
                    setPopUpOpen(true);
                }
            }
        } catch (error) {
            console.log(error);
        }
    }

    const handleConfirmationOk = async () => {
        try {
            if(tabValue === 0){
              const res =  await deleteRunsApply(values, jwt);

                if(res.jobId){
                    triggerInterval(res.jobId)
                    localStorage.setItem('delete_process_jobId',res.jobId)
                }

                dispatch(getRunInfoData(jwt));
                dispatch(getDiskSpacData(jwt));
                setMessage('Deleting data will resume in the background.');
            } else {
                let selectedRuns = Utils.splitRuns(runIds);
                dispatch(deleteRunIdListData(selectedRuns));
                setMessage('Deleting data will resume in the background.');
            }
        } catch (error) {
            console.error('Error deleting data:', error);
            setMessage('Error deleting data:', error);
        }
    
        //setPopUpOpen(false);
        setConfirmationMessage(false);
    };

    const handleConfirmationCancel = () => {
        setConfirmationMessage(false);
        setMessage('');
        setPopUpOpen(false);
        //handleClose();
    };

    // Modify the onChange handler for TextField to update runIds
    const handleRunIdsChange = (e) => {
        setRunIds(e.target.value);
    };

    useEffect(() => {
        const storedValues = JSON.parse(localStorage.getItem('customStorageValues')) || {};
        setValues({
            alertRun: storedValues.alertRun || custom_storage.alertRun,
            allRuns: storedValues.allRuns || custom_storage.allRuns,
            baseCallingQuality: storedValues.baseCallingQuality || custom_storage.baseCallingQuality,
            signalQualityWafer: storedValues.signalQualityWafer || custom_storage.signalQualityWafer,
            systemDebug: storedValues.systemDebug || custom_storage.systemDebug,
            ampRuns: storedValues.ampRuns || custom_storage.ampRuns,
        });
    }, []);

    useEffect(() => {
        const storedValues = JSON.parse(localStorage.getItem('customStorageValues')) || {};
        if (storedValues.alertRun > diskspace) {
            dispatch(setAlertStorageRun(true));
        } else {
            dispatch(setAlertStorageRun(false));
        }
    }, [diskspace]);
    

    // Save values to local storage whenever values change
    useEffect(() => {
        localStorage.setItem('customStorageValues', JSON.stringify(values));
    }, [values]);

    useEffect(() => {
        const storedValuesFromLocalStorage = JSON.parse(localStorage.getItem('customStorageValues')) || custom_storage;
        setStoredValues(storedValuesFromLocalStorage);
    }, []);

    useEffect(() => {
        if(run_info !== undefined) setRunInfoCount(run_info);
    }, [run_info]);

    useEffect(() => {
        if(diskspace !== undefined) setDiskSpace(diskspace);
    }, [diskspace]);

    useEffect(() => {
        if(calculated_storage_free_space !== undefined) setCalcFreeSpace(calculated_storage_free_space);
    }, [calculated_storage_free_space]);

    useEffect(() => {
        if(delete_run_request_status) setDeleteStatus(delete_run_request_status);
        const successMessage = sessionStorage.getItem('delete_complete_message');
        if (successMessage) {
            setMessage(successMessage);
            // Clear the success message after displaying
            sessionStorage.removeItem('delete_complete_message');
            dispatch(getRunInfoData(jwt));
            dispatch(getDiskSpacData(jwt));
        }
    }, [delete_run_request_status]);

    // useEffect(() => {
    //     try {
    //         if (populate_runids !== undefined) {
    //             let exists = populate_runids;
    //             if (exists?.length > 0) {
    //                 // Extract runid_raw values from setSelectedRunIds array
    //                 const runIdsArray = exists.map(item => item.runid);
    //                 // Join the runIdsArray with comma and set it to runIds state
    //                 setRunIds(runIdsArray.join(', '));
    //             }
    //         }
    //     } catch (error) {
    //         console.error('Error populating runIds:', error);
    //     }
    // }, [populate_runids]);

    useEffect(() => {
        try {
            if (populate_runids !== undefined && !runIdsPopulated) {
                let exists = populate_runids;
                if (exists?.length > 0) {
                    // Extract runid_raw values from setSelectedRunIds array
                    const runIdsArray = exists.map(item => item.runid);
                    // Join the runIdsArray with comma and set it to runIds state
                    setRunIds(runIdsArray.join(', '));
                    setRunIdsPopulated(true); // Mark as populated
                }
            }
        } catch (error) {
            console.error('Error populating runIds:', error);
        }
    }, [populate_runids, runIdsPopulated]);

    // Add a new useEffect hook to reset runIds when dialog is closed
    useEffect(() => {
        // Reset runIds when the dialog is closed
        if (!isOpen) {
            setRunIds('');
            setRunIdsPopulated(false); // Reset runIdsPopulated state
            dispatch(setPopulateRuns([]));
        }
    }, [isOpen]);


    const useStyles = makeStyles((theme) => ({
        dialogWrapper: {
            padding: theme.spacing(2),
            position: 'absolute',
            top: theme.spacing(2)
        },
        dialogTitle: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        closeIcon: {
            marginLeft: 'auto',
        },
        storagesContainer: {
            display: 'flex',
            justifyContent: 'space-evenly', 
            gap: 5, 
            margin: '20px 0px'
        },
        alertContainer: {
            display: 'flex',
            alignItems: 'center',
            marginLeft: '20px 0px',
            overflow: 'hidden',
          },
        inputContainer: {
            display: 'flex',
            alignItems: 'center',
            margin: '15px 0px',
            marginLeft: 8
            //overflow: 'hidden',
        },
        outlineInput: {
            height: 20,
            width: 50,
            marginLeft: 10,
            marginRight: 10,
        },
        buttonContainer: {
            display: 'flex',
            justifyContent: 'space-evenly',
            overflow: 'hidden',
        },
        defaultButton: {
            margin: theme.spacing(1),
            background: 'linear-gradient(113.96deg, #E9F5F5 100%, #D9E7F4 100%)',
            border: '0.5px solid #CCCCCC',
            boxSizing: 'border-box',
            borderRadius: '4px',
            color: '#189AB4',
            width: 150,
            '&:disabled': {
                background: '#f0f0f0', // Change this to the desired disabled background color
                color: '#666666', // Change this to the desired disabled text color
            },
        },
        button: {
            margin: theme.spacing(1),
            background: '-webkit-gradient(linear, left top, right bottom, from(#0075a9), to(#003049))',
            border: '0.5px solid #CCCCCC',
            boxSizing: 'border-box',
            borderRadius: '4px',
            color: 'white',
            width: 150,
            '&:disabled': {
                background: '#f0f0f0', // Change this to the desired disabled background color
                color: '#666666', // Change this to the desired disabled text color
            },
        },
        deleteButton: {
            margin: theme.spacing(1),
            background: 'linear-gradient(98.3deg, rgb(128, 0, 0) 10.6%, rgb(255, 0, 0) 97.7%)',
            border: '0.5px solid #CCCCCC',
            boxSizing: 'border-box',
            borderRadius: '4px',
            color: 'white',
            width: 150,
            '&:disabled': {
                background: '#f0f0f0', // Change this to the desired disabled background color
                color: '#666666', // Change this to the desired disabled text color
            },
        },
        allButtonsContainer: {
            display: 'flex',
            justifyContent: 'space-between',
            margin: '15px 0px',
        },
        importButton: {
            margin: theme.spacing(1),
            background: '-webkit-gradient(linear, left top, right bottom, from(#0075a9), to(#003049))',
            border: '0.5px solid #CCCCCC',
            boxSizing: 'border-box',
            borderRadius: '4px',
            color: 'white',
            width: 150,
            marginTop: '20px',
            '&:disabled': {
                background: '#f0f0f0', // Change this to the desired disabled background color
                color: '#666666', // Change this to the desired disabled text color
            },
            fontSize: '13px'
        },
    }));

    const classes = useStyles();

    return (
        <>
        <Dialog disableEnforceFocus onClose={handleClose} aria-labelledby="customized-dialog-title" open={isOpen} fullWidth maxWidth={'md'} classes={{paper: classes.dialogWrapper}} BackdropProps={Boolean(deleteStatus) ? { onClick: () => {} } : {}}>
        <DialogTitle>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant="h6">Storage Management</Typography>
                <IconButton onClick={handleClose}>
                    <CloseIcon />
                </IconButton>
            </div>
        </DialogTitle>
        <div style={{ display: 'grid', gridTemplateRows: 'repeat(2, 1fr)', gridTemplateColumns: 'repeat(2, 1fr)', gap: '10px', marginBottom: 30, marginLeft: 30 }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <Typography>Stored Runs: {runInfoCount}</Typography>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <Typography>Available Storage (runs): {diskSpace}</Typography>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <Typography>Storage to be freed with current rules (runs): {calcFreeSpace}</Typography>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <Typography>Last clean-up completed at: {lastCleanupDate}</Typography>
            </div>
        </div>
        <Tabs value={tabValue} onChange={handleTabChange} indicatorColor='primary'>
                <Tab label="Rules" />
                <Tab label="Runs" />
        </Tabs>
            <DialogContent dividers style={{ overflow: 'hidden', margin: 0 }}>
                <Box hidden={tabValue !== 0}>
                    <div className={classes.inputContainer}>
                        <Typography>Alert when available storage is below:</Typography>
                        <OutlinedInput
                            id="alertRun"
                            name='alertRun'
                            type="number"
                            value={values.alertRun}
                            onChange={handleInputChange}
                            className={classes.outlineInput}
                            disabled={Boolean(delete_run_request_status)}
                        />
                        <Typography>runs</Typography>
                    </div>
                    <Stack spacing={1} margin={1}>
                        <div className={classes.inputContainer}>
                            <Typography>Remove all data for runs older than:</Typography>
                            <OutlinedInput
                                id="allRuns"
                                name='allRuns'
                                type="number"
                                value={values.allRuns}
                                onChange={handleInputChange}
                                className={classes.outlineInput}
                                disabled={Boolean(delete_run_request_status)}
                            />
                            <Typography>months</Typography>
                        </div>
                        <div className={classes.inputContainer}>
                            <Typography>Remove base-calling quality data for runs older than:</Typography>
                            <OutlinedInput
                                id="baseCallingQuality"
                                name='baseCallingQuality'
                                type="number"
                                value={values.baseCallingQuality}
                                onChange={handleInputChange}
                                className={classes.outlineInput}
                                disabled={Boolean(delete_run_request_status)}
                            />
                            <Typography>months</Typography>
                        </div>
                        <div className={classes.inputContainer}>
                            <Typography>Remove signal quality and wafer maps data for runs older than:</Typography>
                            <OutlinedInput
                                id="signalQualityWafer"
                                name='signalQualityWafer'
                                type="number"
                                value={values.signalQualityWafer}
                                onChange={handleInputChange}
                                className={classes.outlineInput}
                                disabled={Boolean(delete_run_request_status)}
                            />
                            <Typography>months</Typography>
                        </div>
                        <div className={classes.inputContainer}>
                            <Typography>Remove system debug data for runs older than:</Typography>
                            <OutlinedInput
                                id="systemDebug"
                                name='systemDebug'
                                type="number"
                                value={values.systemDebug}
                                onChange={handleInputChange}
                                className={classes.outlineInput}
                                disabled={Boolean(delete_run_request_status)}
                            />
                            <Typography>months</Typography>
                        </div>
                        <div className={classes.inputContainer}>
                            <Typography>Remove amp runs older than:</Typography>
                            <OutlinedInput
                                id="ampRuns"
                                name='ampRuns'
                                type="number"
                                value={values.ampRuns}
                                onChange={handleInputChange}
                                className={classes.outlineInput}
                                disabled={Boolean(delete_run_request_status)}
                            />
                            <Typography>months</Typography>
                        </div>
                    </Stack> 
                    <div className={classes.buttonContainer}>
                        <DialogActions>
                            <Button autoFocus onClick={handleDefaultClick} color="primary" className={classes.defaultButton} disabled={Boolean(disableStorageActions)}>
                            Default
                            </Button>
                            <Button autoFocus onClick={handleApplyClick} color="primary" className={classes.button} disabled={Boolean(disableStorageActions)}>
                            Apply
                            </Button>
                        </DialogActions>
                    </div>
                </Box>
                <Box hidden={tabValue !== 1}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={10}>
                        {/* <TextareaAutosize
                            id="runIds"
                            label="Enter Run IDs"
                            multiline
                            variant="outlined"
                            fullWidth
                            value={runIds}
                            onChange={handleRunIdsChange}
                            InputProps={{
                            style: {
                                marginTop: '10px',
                                height: 'auto',
                                maxHeight: '150px',
                                overflowY: 'auto',
                                border: '1px solid #bdbdbd',
                            },
                            }}
                            InputLabelProps={{
                                style: {
                                    top: '-5px',
                                },
                            }}
                        /> */}
                        <div style={{ position: 'relative', maxWidth: '100%', maxHeight: '150px', overflow: 'auto' }}>
                            <TextareaAutosize
                                id="runIds"
                                aria-label="Enter Run IDs"
                                minRows={3} // Adjust the number of minimum rows as needed
                                placeholder="Enter Run IDs"
                                value={runIds}
                                onChange={handleRunIdsChange}
                                style={{
                                    width: '100%',
                                    border: '1px solid #bdbdbd',
                                    resize: 'vertical', // Allow vertical resizing
                                    boxSizing: 'border-box',
                                    padding: '8px', // Adjust padding as needed
                                    outline: 'none', // Remove default outline
                                    borderRadius: '4px', // Add border radius
                                    fontFamily: 'inherit', // Use inherit font family
                                    fontSize: 'inherit', // Use inherit font size
                                }}
                            />
                        </div>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6} md={4}>
                                <FormControl fullWidth variant="outlined">
                                    <Row style={{ justifyContent: 'center', display: 'flex', margin: '10px'}}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <Col lg={6} style={{ marginBottom: '20px' }}>
                                            <DesktopDatePicker
                                                label="Date From:"
                                                inputFormat="MM/DD/YYYY"
                                                value={dateFrom}
                                                name="from"
                                                onChange={handleDateFromChange}
                                                renderInput={(params) => <TextField {...params} InputProps={{ style: { fontSize: '1.1rem' } }} />}
                                            />
                                        </Col>
                                        <Col lg={6} style={{ marginBottom: '20px' }}>
                                            <DesktopDatePicker
                                                label="Date To:"
                                                inputFormat="MM/DD/YYYY"
                                                value={dateTo}
                                                name="to"
                                                onChange={handleDateToChange}
                                                renderInput={(params) => <TextField {...params} InputProps={{ style: { fontSize: '1.1rem' } }} />}
                                            />
                                        </Col>
                                    </LocalizationProvider>
                                    </Row>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    className={classes.importButton}
                                    onClick={handleImportRunIdsClick}
                                    disabled={Boolean(delete_run_request_status)}
                                >
                                    Import Run Ids
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </DialogContent>
                <div className={classes.buttonContainer}>
                    <DialogActions>
                    <Button className={classes.deleteButton} autoFocus onClick={handleDeleteClick} color="primary" disabled={Boolean(disableStorageActions)}>
                        Delete
                    </Button>
                    </DialogActions>
                </div>
        </Dialog>
        {message ? (
            <PopupMessage
                isOpen={PopUpOpen}
                message={message}
                confirmationMessage={confirmationMessage}
                onOk={confirmationMessage ? handleConfirmationOk : handleConfirmationCancel}
                onCancel={handleConfirmationCancel}
            />
        ) : null}
        </>
    );
}

export default Storage;