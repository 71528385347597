import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { setCustomFilters, setCustomFiltersApplied, setVersion, setEditMode, setRunListDataSearched, setSearchTerm, getRunDataAllGroupedAsync, setNumberOfCustomFiltersApplied, setJWT, setRunListDataFiltered, setJWTExp, logOutCurrentUser, resetState, getRunDataAllGroupedValidatedAsync,
 getRunInfoData, getDiskSpacData, calculcateStorageFreeSpace } from "../actions/index";
import Root from '../root/root';
import * as Utils from '../../utils/utils';

const RootContainer = () => {
    const [runData, setRunData] = React.useState([]);

    const state = useSelector(state => state);
    const dispatch = useDispatch();

    const redirect = () => window.location.href = `${process.env.REACT_APP_API_URL_AUTH}/logout/?from=nexus`;

    useEffect(() => {
        document.title = state.runId !== undefined && state.runId !== null && state.runId !== '' ? `Nexus - ${state.is_unified_row_selected ? Utils.getRunIdWithoutCamera(state.runId) : state.runId}` : `Nexus`;
    }, [state.runId, state.is_unified_row_selected]);

    useEffect(() => {
        if (process.env.REACT_APP_VERSION !== undefined)
            dispatch(setVersion(process.env.REACT_APP_VERSION));
        if (state.runlist.length === 0 && !state.is_run_data_loading) {
            dispatch(getRunDataAllGroupedAsync(100, state.jwt));
            dispatch(getRunInfoData(state.jwt));
            dispatch(getDiskSpacData(state.jwt));
            dispatch(calculcateStorageFreeSpace(state.custom_storage, state.jwt));
        }
    }, []);

    useEffect(() => {
        try {
            if (Date.now() >= state.jwt_exp) {
                dispatch(setJWT(undefined));
                dispatch(setJWTExp(undefined));
                dispatch(logOutCurrentUser());
                dispatch(resetState());
                redirect();
            }
        }
        catch { }
    }, [state]);

    useEffect(() => {
        try {
            if (state.runlist !== undefined && state.runlist.length > 0 && state.runlist_searched.length === 0) {
                if (state.is_custom_filters_applied) {
                    let filters = state.custom_filters;
                    let dataTofilter = state.runlist;
                    let filtered = Utils.filterByCustomFilters(filters, dataTofilter, dispatch, setNumberOfCustomFiltersApplied);
                    if (filtered !== undefined && filtered.length > 0) {
                        dispatch(setCustomFiltersApplied(true));
                        dispatch(setRunListDataFiltered(filtered));
                    }
                }
                let allRunList = state.runlist.map(row => {
                    if (row.subRows !== undefined && row.subRows.length > 0) {
                        row.subRows.forEach(m => m.runtime = m.runtime != null && m.runtime != undefined && m.runtime != "null" && m.runtime != "" ? Utils.time_convert(parseInt(m.runtime)) : "00:00");
                    }
                    return row;
                });
                const result = state.runlist.reduce((resultArray, item, index) => {
                    const chunkIndex = Math.floor(index / 1500)

                    if (!resultArray[chunkIndex]) {
                        resultArray[chunkIndex] = [] // start a new chunk
                    }

                    resultArray[chunkIndex].push(item)

                    return resultArray
                }, []);
                if (result !== undefined && result.length > 0) {
                    let currData = [];
                    let timeouts = [];
                    result.forEach((item, i) => {
                        if (i === 0) {
                            let dt = Utils.getRunList(item);
                            setRunData(dt);
                            currData = dt;
                        }
                        else {
                            let timeOut = setTimeout(() => {
                                let dt = Utils.getRunList(item);
                                currData = [...currData, ...dt];
                                setRunData(currData);
                                if (currData.length === allRunList.length) {
                                    timeouts.forEach(timeout => clearTimeout(timeout));
                                }
                            }, 300);
                            timeouts.push(timeOut);
                        }
                    });
                }
                else setRunData(Utils.getRunList(state.runlist));
            }
        }
        catch { }
    }, [state.runlist]);

    //updating the view after Details editing by user
    useEffect(() => {
        if (state.runlist !== undefined && state.runlist.length > 0 && state.is_details_data_saved) {
            setRunData(Utils.getRunList(state.runlist));
            dispatch(setRunListDataSearched([]));
            dispatch(setSearchTerm(undefined, 'all'));
            if (state.is_in_edit_mode === true) dispatch(setEditMode(false));
        }
    }, [state.is_details_data_saved]);

    //updating the view after Analysis Comments editing by user
    useEffect(() => {
        if (state.runlist !== undefined && state.runlist.length > 0 && state.is_analysis_comments_data_saved) {
            setRunData(Utils.getRunList(state.runlist));
            dispatch(setRunListDataSearched([]));
            dispatch(setSearchTerm(undefined, 'all'));
            if (state.is_in_edit_mode === true) dispatch(setEditMode(false));
        }
    }, [state.is_analysis_comments_data_saved]);

    useEffect(() => {
        if (state.runlist_searched !== undefined && state.runlist_searched.length > 0) {
            setRunData(state.runlist_searched);
        }
        else if (state.runlist_filtered !== undefined && state.runlist_filtered.length > 0 && state.is_custom_filters_applied === true) {
            setRunData(Utils.getRunList(state.runlist_filtered));
        }
        else setRunData(Utils.getRunList(state.runlist));
    }, [state.runlist_searched]);

    useEffect(() => {
        if (state.runlist_filtered !== undefined && state.runlist_filtered.length > 0 && state.is_custom_filters_applied === true) {
            setRunData(Utils.getRunList(state.runlist_filtered));
        }
    }, [state.runlist_filtered]);

    useEffect(() => {
        if (state.is_custom_filters_applied === false && state.runlist !== undefined && state.runlist.length > 0) {
            setRunData(Utils.getRunList(state.runlist));
        }

    }, [state.is_custom_filters_applied]);

    const handleClearAdvancedSearch = e => {
        try {
            dispatch(setCustomFiltersApplied(false));
            let f = Utils.getColumnFilters();
            f.analysisstatus.series = f.analysisstatus.default;
            f.analysisstatus.op = 'a';
            dispatch(setCustomFilters(f));
            setRunData(Utils.getRunList(state.runlist));
        }
        catch { }
    }

    return (
        <Root mainTableData={runData} handleClearAdvancedSearch={handleClearAdvancedSearch} />
    )
};

export default RootContainer;