export const LOAD_RUN_LIST_DATA = "LOAD_RUN_LIST_DATA";
export const SET_RUN_LIST_DATA_FILTERED = "SET_RUN_LIST_DATA_FILTERED";
export const SET_RUN_LIST_DATA = "SET_RUN_LIST_DATA";
export const SET_ACTIVE_TAB_NAME = "SET_ACTIVE_TAB_NAME";
export const SET_CURRENT_RUN_DATA = "SET_CURRENT_RUN_DATA";
export const SET_IS_LOADING = "SET_IS_LOADING";
export const GET_SIGNAL_PLOTS_DATA = "GET_SIGNAL_PLOTS_DATA";
export const SET_SIGNAL_PLOTS_RENDER_DATA = "SET_SIGNAL_PLOTS_RENDER_DATA";
export const GET_HISTOGRAM_PLOTS_DATA = "GET_HISTOGRAM_PLOTS_DATA";
export const SET_HISTOGRAM_PLOTS_RENDER_DATA = "SET_HISTOGRAM_PLOTS_RENDER_DATA";
export const SET_FLOW = "SET_FLOW";
export const SET_TILE_AREA = "SET_TILE_AREA";
export const SET_INSTRUMENT_ID = "SET_INSTRUMENT_ID";
export const SET_RUN_ID = "SET_RUN_ID";
export const GET_CIRCLE_PLOTS_DATA = "GET_CIRCLE_PLOTS_DATA";
export const SET_CIRCLE_PLOTS_RENDER_DATA = "SET_CIRCLE_PLOTS_RENDER_DATA";
export const SET_IS_SIGNAL_PLOTS_LOADING = "SET_IS_SIGNAL_PLOTS_LOADING";
export const SET_IS_HISTOGRAM_PLOTS_LOADING = "SET_IS_HISTOGRAM_PLOTS_LOADING";
export const SET_DENSITY_SPINNER = "SET_DENSITY_SPINNER";
export const SET_SIGNAL_SPINNER = "SET_SIGNAL_SPINNER";
export const SET_SNR_SPINNER = "SET_SNR_SPINNER";
export const SET_STATS_TABLE_DATA = "SET_STATS_TABLE_DATA";
export const SET_THETA_PROFILING_PLOTS_RENDER_DATA = "SET_THETA_PROFILING_PLOTS_RENDER_DATA";
export const SET_THETA_PROFILING_PLOTS_RADIUS = "SET_THETA_PROFILING_PLOTS_RADIUS";
export const GET_RUN_CONFIGURATION_TABLE_DATA = "GET_RUN_CONFIGURATION_TABLE_DATA";
export const SET_RUN_CONFIGURATION_TABLE_RENDER_DATA = "SET_RUN_CONFIGURATION_TABLE_RENDER_DATA";
export const GET_RUN_CONFIGURATION_PLOTS = "GET_RUN_CONFIGURATION_PLOTS";
export const CHECK_RUN_IS_BARCODED = "CHECK_RUN_IS_BARCODED";
export const SET_RUN_IS_BARCODED = "SET_RUN_IS_BARCODED";
export const GET_BEADS_FILTERING_TABLE_DATA = "GET_BEADS_FILTERING_TABLE_DATA";
export const SET_BEADS_FILTERING_TABLE_RENDER_DATA = "SET_BEADS_FILTERING_TABLE_RENDER_DATA";
export const GET_BEADS_FILTERING_PLOTS = "GET_BEADS_FILTERING_PLOTS";
export const SET_BEADS_FILTERING_PLOTS_IS_LOADING = "SET_BEADS_FILTERING_PLOTS_IS_LOADING";
export const GET_BARCODE_STATISTICS_TABLE_DATA = "GET_BARCODE_STATISTICS_TABLE_DATA";
export const SET_BARCODE_STATISTICS_TABLE_RENDER_DATA = "SET_BARCODE_STATISTICS_TABLE_RENDER_DATA";
export const GET_BER_TABLE_DATA = "GET_BER_TABLE_DATA";
export const SET_BER_TABLE_RENDER_DATA = "SET_BER_TABLE_RENDER_DATA";
export const GET_BER_HMER_TABLE_DATA = "GET_BER_HMER_TABLE_DATA";
export const SET_BER_HMER_TABLE_RENDER_DATA = "SET_BER_HMER_TABLE_RENDER_DATA";
export const GET_ERROR_PLOTS = "GET_ERROR_PLOTS";
export const GET_RLQ_TABLE_DATA = "GET_RLQ_TABLE_DATA";
export const SET_RLQ_TABLE_RENDER_DATA = "SET_RLQ_TABLE_RENDER_DATA";
export const GET_FMETRICS_TABLE_DATA = "GET_FMETRICS_TABLE_DATA";
export const SET_FMETRICS_TABLE_RENDER_DATA = "SET_FMETRICS_TABLE_RENDER_DATA";
export const GET_BARCODE_COVERAGE_PLOTS = "GET_BARCODE_COVERAGE_PLOTS";
export const SET_BARCODE_COVERAGE_PLOTS_IS_LOADING = "SET_BARCODE_COVERAGE_PLOTS_IS_LOADING";
export const GET_BASE_QUALITY_PLOTS = "GET_BASE_QUALITY_PLOTS";
export const GET_PHASING_PLOTS = "GET_PHASING_PLOTS";
export const GET_SIGNALS_PLOTS = "GET_SIGNALS_PLOTS";
export const GET_SIGNALS_PLOTS_PNG = "GET_SIGNALS_PLOTS_PNG";
export const GET_COVERAGE_GC_PLOTS = "GET_COVERAGE_GC_PLOTS";
export const GET_UNIFORMITY_PLOTS = "GET_UNIFORMITY_PLOTS";
export const GET_UNIFORMITY_SIGNAL_PLOTS = "GET_UNIFORMITY_SIGNAL_PLOTS";
export const GET_Q_TABLE_DATA = "GET_Q_TABLE_DATA";
export const SET_Q_TABLE_RENDER_DATA = "SET_Q_TABLE_RENDER_DATA";
export const GET_F_TABLE_DOWNLOAD_DATA = "GET_F_TABLE_DOWNLOAD_DATA";
export const GET_HG_BASECALLING_REPORT = "GET_HG_BASECALLING_REPORT";
export const GET_EC_BASECALLING_REPORT = "GET_EC_BASECALLING_REPORT";
export const GET_SIGNALS_LINEARITY_TABLE_DATA = "GET_SIGNALS_LINEARITY_TABLE_DATA";
export const SET_SIGNALS_LINEARITY_TABLE_RENDER_DATA = "SET_SIGNALS_LINEARITY_TABLE_RENDER_DATA";
export const GET_SIGNALS_LINEARITY_PLOTS = "GET_SIGNALS_LINEARITY_PLOTS";
export const GET_OPTICS_PLOTS_DATA = "GET_OPTICS_PLOTS_DATA";
export const SET_OPTICS_PLOTS_RENDER_DATA = "SET_OPTICS_PLOTS_RENDER_DATA";
export const GET_BEAD_LOSS_PLOTS_DATA = "GET_BEAD_LOSS_PLOTS_DATA";
export const GET_BEAD_LOSS_PLOTS_DATA_CSV = "GET_BEAD_LOSS_PLOTS_DATA_CSV";
export const SET_BEAD_LOSS_DATA_LOADING = "SET_BEAD_LOSS_DATA_LOADING";
export const GET_HISTOGRAM_BY_FLOWS_PLOTS_DATA = "GET_HISTOGRAM_BY_FLOWS_PLOTS_DATA";
export const SET_DENSITY_PLOTS_RENDER_DATA = "SET_DENSITY_PLOTS_RENDER_DATA";
export const SET_STATS_DENSITY_TABLE_DATA = "SET_STATS_DENSITY_TABLE_DATA";
export const GET_CLUMPINGS_TABLE_DATA = "GET_CLUMPINGS_TABLE_DATA";
export const SET_CLUMPINGS_TABLE_RENDER_DATA = "SET_CLUMPINGS_TABLE_RENDER_DATA";
export const SET_IS_RUN_DATA_LOADING = "SET_IS_RUN_DATA_LOADING";
export const SET_USER = "SET_USER";
export const GET_HEALTH_DATA_TEMPERATURE_PLOTS = "GET_HEALTH_DATA_TEMPERATURE_PLOTS";
export const SET_HEALTH_DATA_TEMPERATURE_RENDER_DATA = "SET_HEALTH_DATA_TEMPERATURE_RENDER_DATA";
export const SET_HEALTH_DATA_IS_LOADING = "SET_HEALTH_DATA_IS_LOADING";
export const GET_HEALTH_DATA_HUMIDITY_PLOTS = "GET_HEALTH_DATA_HUMIDITY_PLOTS";
export const SET_HEALTH_DATA_HUMIDITY_RENDER_DATA = "SET_HEALTH_DATA_HUMIDITY_RENDER_DATA";
export const GET_COVERAGE_PLOTS = "GET_COVERAGE_PLOTS";
export const GET_PHASING_TABLE_DATA = "GET_PHASING_TABLE_DATA";
export const GET_PHASING_TABLE_RENDER_DATA = "GET_PHASING_TABLE_RENDER_DATA";
export const GET_COVGCBIAS_TABLE_DATA = "GET_COVGCBIAS_TABLE_DATA";
export const GET_COVGCBIAS_TABLE_RENDER_DATA = "GET_COVGCBIAS_TABLE_RENDER_DATA";
export const CHECK_VCREPORT_EXIST = "CHECK_VCREPORT_EXIST";
export const GET_VCREPORT_PLOTS = "GET_VCREPORT_PLOTS";
export const IS_MAIN_TABLE_FIRST_PAGE_LOADED = "IS_MAIN_TABLE_FIRST_PAGE_LOADED";
export const IS_NUM_FLOWS_100_LOADED = "IS_NUM_FLOWS_100_LOADED";
export const LOAD_AMP_LIST_DATA = "LOAD_AMP_LIST_DATA";
export const SET_IS_AMP_DATA_LOADING = "SET_IS_AMP_DATA_LOADING";
export const SET_CURRENT_AMP_RUN_DATA = "SET_CURRENT_AMP_RUN_DATA";
export const SET_AMP_RUN_ID = "SET_AMP_RUN_ID";
export const GET_AMP_PLOTS_DATA = "GET_AMP_PLOTS_DATA";
export const SET_IS_AMP_PLOTS_DATA_LOADING = "SET_IS_AMP_PLOTS_DATA_LOADING";
export const SET_IS_OPTICS_PLOTS_DATA_LOADING = "SET_IS_OPTICS_PLOTS_DATA_LOADING";
export const SET_ACTIVE_INSTR_HEALTH_SUB_TAB_NAME = "SET_ACTIVE_INSTR_HEALTH_SUB_TAB_NAME";
export const GET_OPTICS_PLOTS_LASER_DATA = "GET_OPTICS_PLOTS_LASER_DATA";
export const SET_OPTICS_PLOTS_LASER_RENDER_DATA = "SET_OPTICS_PLOTS_LASER_RENDER_DATA";
export const SET_IS_OPTICS_PLOTS_LASER_DATA_LOADING = "SET_IS_OPTICS_PLOTS_LASER_DATA_LOADING";
export const GET_IMG_DEFORM_DATA = "GET_IMG_DEFORM_DATA";
export const SET_IS_IMG_DEFORM_PLOTS_DATA_LOADING = "SET_IS_IMG_DEFORM_PLOTS_DATA_LOADING";
export const SET_IMG_DEFORM_RENDER_DATA = "SET_IMG_DEFORM_RENDER_DATA";
export const GET_SAM_REPORT = "GET_SAM_REPORT";
export const SET_SAM_REPORT_IS_LOADING = "SET_SAM_REPORT_IS_LOADING";
export const GET_FWHM_PLOTS_DATA = "GET_FWHM_PLOTS_DATA";
export const SET_FWHM_PLOTS_IS_LOADING = "SET_FWHM_PLOTS_IS_LOADING";
export const GET_FWHM_PLOTS_CAMERA_DATA = "GET_FWHM_PLOTS_CAMERA_DATA";
export const GET_TEMPLATE_REPORT = "GET_TEMPLATE_REPORT";
export const SET_TEMPLATE_REPORT_IS_LOADING = "SET_TEMPLATE_REPORT_IS_LOADING";
export const SET_TEMPLATE_REPORT_RENDER_DATA = "SET_TEMPLATE_REPORT_RENDER_DATA";
export const GET_SUPPLEMENTARY_REPORT = "GET_SUPPLEMENTARY_REPORT";
export const SET_SUPPLEMENTARY_REPORT_IS_LOADING = "SET_SUPPLEMENTARY_REPORT_IS_LOADING";
export const SET_SUPPLEMENTARY_REPORT_RENDER_DATA = "SET_SUPPLEMENTARY_REPORT_RENDER_DATA";
export const SET_ACTIVE_TEMPLATE_REPORT_SUB_TAB_NAME = "SET_ACTIVE_TEMPLATE_REPORT_SUB_TAB_NAME";
export const SET_LATE_FLOW_METRICS_IS_LOADING = "SET_LATE_FLOW_METRICS_IS_LOADING";
export const GET_LATE_FLOW_METRICS_DATA = "GET_LATE_FLOW_METRICS_DATA";
export const SET_TOOL_NOISE_PLOTS_IS_LOADING = "SET_TOOL_NOISE_PLOTS_IS_LOADING";
export const GET_TOOL_NOISE_PLOTS = "GET_TOOL_NOISE_PLOTS";
export const SET_TOOL_NOISE_TABLE_DATA_IS_LOADING = "SET_TOOL_NOISE_TABLE_DATA_IS_LOADING";
export const GET_TOOL_NOISE_TABLE_DATA = "GET_TOOL_NOISE_TABLE_DATA";
export const SET_CUSTOM_FILTERS = "SET_CUSTOM_FILTERS";
export const SET_CUSTOM_FILTERS_APPLIED = "SET_CUSTOM_FILTERS_APPLIED";
export const SET_RUN_LIST_DOWNLOAD_DATA = "SET_RUN_LIST_DOWNLOAD_DATA";
export const SET_RUN_LIST_AMP_DOWNLOAD_DATA = "SET_RUN_LIST_AMP_DOWNLOAD_DATA";
export const SET_ALL_RUN_LIST_DATA_SHOWN_BY_FLOW_NUM_100 = "SET_ALL_RUN_LIST_DATA_SHOWN_BY_FLOW_NUM_100";
export const SAVE_ANALYSIS_COMMENT_TO_DB = "SAVE_ANALYSIS_COMMENT_TO_DB";
export const SET_ANALYSIS_COMMENT_DATA_SAVED = "SET_ANALYSIS_COMMENT_DATA_SAVED";
export const SAVE_DETAILS_DATA_TO_DB = "SAVE_DETAILS_DATA_TO_DB";
export const SET_DETAILS_DATA_SAVED = "SET_DETAILS_DATA_SAVED";
export const SET_ISAUTHENTICATED = "SET_ISAUTHENTICATED";
export const LOGOUT_CURRENT_USER = "LOGOUT_CURRENT_USER";
export const RESET_STATE = "RESET_STATE";
export const SET_EDIT_MODE = "SET_EDIT_MODE";
export const SET_RUN_LIST_DATA_SEARCHED = "SET_RUN_LIST_DATA_SEARCHED";
export const SET_RUN_LIST_DATA_SEARCHED_AMP = "SET_RUN_LIST_DATA_SEARCHED_AMP";
export const SET_NUMBER_OF_CUSTOM_FILTERS_APPLIED = "SET_NUMBER_OF_CUSTOM_FILTERS_APPLIED";
export const SET_SEARCH_TERM = "SET_SEARCH_TERM";
export const IS_DEFAULT_FILTER = "IS_DEFAULT_FILTER";
export const SET_SEARCH_TERM_AMP = "SET_SEARCH_TERM_AMP";
export const IS_EDIT_ALLOWED = "IS_EDIT_ALLOWED";
export const SET_ERROR_PLOT_IS_LOADING = "SET_ERROR_PLOT_IS_LOADING";
export const SET_READ_LENGTH_PLOT_IS_LOADING = "SET_READ_LENGTH_PLOT_IS_LOADING";
export const GET_READ_LENGTH_PLOTS = "GET_READ_LENGTH_PLOTS";
export const SET_READ_LENGTH_PLOTS_BY_CAMERA_VALUE = "SET_READ_LENGTH_PLOTS_BY_CAMERA_VALUE";
export const SET_ERROR_PLOTS_BY_CAMERA_VALUE = "SET_ERROR_PLOTS_BY_CAMERA_VALUE";
export const GET_RSQ_PLOT = "GET_RSQ_PLOT";
export const SET_RSQ_PLOTS_BY_CAMERA_VALUE = "SET_RSQ_PLOTS_BY_CAMERA_VALUE";
export const SET_RSQ_PLOT_IS_LOADING = "SET_RSQ_PLOT_IS_LOADING";
export const GET_POLY_PLOTS = "GET_POLY_PLOTS";
export const SET_POLY_PLOTS_IS_LOADING = "SET_POLY_PLOTS_IS_LOADING";
export const GET_FWHM_PLOTS = "GET_FWHM_PLOTS";
export const RUN_SUMMARY_DENSITY_PLOT_BY_CAMERA_VALUE = "RUN_SUMMARY_DENSITY_PLOT_BY_CAMERA_VALUE";
export const SET_RUN_SUMMARY_DENSITY_PLOT_DATA = "SET_RUN_SUMMARY_DENSITY_PLOT_DATA";
export const GET_RUN_SUMMARY_DENSITY_PLOT_DATA = "GET_RUN_SUMMARY_DENSITY_PLOT_DATA";
export const SET_RUN_SUMMARY_DENSITY_PLOT_IS_LOADING = "SET_RUN_SUMMARY_DENSITY_PLOT_IS_LOADING";
export const SET_RUN_SUMMARY_DENSITY_PLOT_RENDER_DATA = "SET_RUN_SUMMARY_DENSITY_PLOT_RENDER_DATA";
export const SET_VERSION = "SET_VERSION";
export const READS_FILTERING_PLOT_BY_CAMERA_VALUE = "READS_FILTERING_PLOT_BY_CAMERA_VALUE";
export const SET_FMETRICS_DATA_IS_LOADING = "SET_FMETRICS_DATA_IS_LOADING";
export const GET_COVERAGE_PLOTS_DATA = "GET_COVERAGE_PLOTS_DATA";
export const GET_COVERAGE_PLOTS_DATA_IS_LOADING = "GET_COVERAGE_PLOTS_DATA_IS_LOADING";
export const SET_TEMPLATE_REPORT_EXIST = "SET_TEMPLATE_REPORT_EXIST";
export const SET_HG_BASECALLING_REPORT_EXIST = "SET_HG_BASECALLING_REPORT_EXIST";
export const SET_EC_BASECALLING_REPORT_EXIST = "SET_EC_BASECALLING_REPORT_EXIST";
export const GET_CHART_VIEW_PLOTS_DATA = "GET_CHART_VIEW_PLOTS_DATA";
export const SET_CHART_VIEW_PLOTS_DATA_IS_LOADING = "SET_CHART_VIEW_PLOTS_DATA_IS_LOADING";
export const GET_BASE_QUALITY_BER_PER_FLOW_PLOT = "GET_BASE_QUALITY_BER_PER_FLOW_PLOT";
export const SET_ALERT = "SET_ALERT";
export const CLEAR_ALERT = "CLEAR_ALERT";
export const SET_IS_UNIFIED_ROW_SELECTED = "SET_IS_UNIFIED_ROW_SELECTED";
export const GET_HOMO_INDEL_ERROR = "GET_HOMO_INDEL_ERROR";
export const GET_BARCODE_DETAILS_READ_LENGTH_PLOT_DATA = "GET_BARCODE_DETAILS_READ_LENGTH_PLOT_DATA";
export const SET_POLY_PLOTS_BY_CAMERA_VALUE = "SET_POLY_PLOTS_BY_CAMERA_VALUE";
export const ADD_PLOTLY_PLOT_TO_LIST = "ADD_PLOTLY_PLOT_TO_LIST";
export const GET_FWHM_CSV_DATA = "GET_FWHM_CSV_DATA";
export const GET_AMP_SINGLE_RUN_DATA = "GET_AMP_SINGLE_RUN_DATA";
export const SET_IS_AMP_SINGLE_RUN_LOADING = "SET_IS_AMP_SINGLE_RUN_LOADING";
export const SET_BY_CAMERA_VALUE = "SET_BY_CAMERA_VALUE";
export const SET_SHOW_FROZEN_HEADER = "SET_SHOW_FROZEN_HEADER";
export const IS_VALID_DATA_LOADING = "IS_VALID_DATA_LOADING";
export const SET_H5PLOTS_VALID_DATA = "SET_H5PLOTS_VALID_DATA";
export const LOAD_H5PLOT_VALID_DATA = "LOAD_H5PLOT_VALID_DATA";
export const SET_VALID_DATA = "SET_VALID_DATA";
export const LOAD_VALID_DATA = "LOAD_VALID_DATA";
export const VALIDATION_STATUS = "VALIDATION_STATUS";
export const SET_CALCULATE_COVERAGE_DATA = "SET_CALCULATE_COVERAGE_DATA";
export const SET_JWT = "SET_JWT";
export const SET_JWT_EXP = "SET_JWT_EXP";
export const LOAD_VALID_AMP_DATA = "LOAD_VALID_AMP_DATA";
export const SINGLE_VALID_RUN_DATA = "SINGLE_VALID_RUN_DATA";
export const FWHM_VALID_RUN_DATA = "FWHM_VALID_RUN_DATA";
export const GET_RUN_CONFIGURATION_VALIDATED_DATA = "GET_RUN_CONFIGURATION_VALIDATED_DATA";
export const GET_ERROR_PLOTS_VALIDATED = "GET_ERROR_PLOTS_VALIDATED";
export const GET_VALIDATED_READ_LENGTH_PLOTS = "GET_VALIDATED_READ_LENGTH_PLOTS";
export const GET_PHASING_VALIDATED_DATA = "GET_PHASING_VALIDATED_DATA";
export const GET_READS_FILTERING_PLOTS_VALIDATED = "GET_READS_FILTERING_PLOTS_VALIDATED";
export const GET_RSQ_PLOT_VALIDATED = "GET_RSQ_PLOT_VALIDATED";
export const GET_BARCODE_STATISTICS_VALIDATED = "GET_BARCODE_STATISTICS_VALIDATED";
export const GET_CLUMPINGS_VALIDATED = "GET_CLUMPINGS_VALIDATED";
export const GET_LINEARITY_VALIDATED = "GET_LINEARITY_VALIDATED";
export const GET_BER_TABLE_VALIDATED = "GET_BER_TABLE_VALIDATED";
export const GET_BER_HMER_TABLE_VALIDATED = "GET_BER_HMER_TABLE_VALIDATED";
export const GET_RLQ_TABLE_VALIDATED = "GET_RLQ_TABLE_VALIDATED";
export const GET_BEADS_FILTERING_VALIDATED = "GET_BEADS_FILTERING_VALIDATED";
export const GET_FMETRICS_TABLE_VALIDATED = "GET_FMETRICS_TABLE_VALIDATED";
export const GET_Q_TABLE_VALIDATED = "GET_Q_TABLE_VALIDATED";
export const GET_RUNINFO_DATA = "GET_RUNINFO_DATA";
export const SET_CUSTOM_STORAGE = "SET_CUSTOM_STORAGE";
export const GET_DISKSPACE = "GET_DISKSPACE";
export const SET_ALERT_STORAGE_RUN = "SET_ALERT_STORAGE_RUN";
export const DELETE_RUN_REQUEST_STATUS = 'DELETE_RUN_REQUEST_STATUS';
export const CALCULATED_CUSTOM_STORAGE_SPACE = 'CALCULATED_CUSTOM_STORAGE_SPACE';
export const ACTIVE_BARCODE_TABLE_BUTTON = "ACTIVE_BARCODE_TABLE_BUTTON";
export const ADD_PLOTLY_URI_LIST = "ADD_PLOTLY_URI_LIST";
export const ADD_RUN_DETAILS_TABLE_PDF = "ADD_RUN_DETAILS_TABLE_PDF";
export const ADD_BEADS_FFILTER_TABLE_PDF = "ADD_BEADS_FFILTER_TABLE_PDF";
export const TIME_ZONE_SELECTED = "TIME_ZONE_SELECTED";
export const POPULATE_RUNIDS = "POPULATE_RUNIDS";
export const SET_IS_IMPORT_FIELD_LOADING = "SET_IS_IMPORT_FIELD_LOADING";
export const GET_VALIDATED_READ_FILTER_PLOTS = "GET_VALIDATED_READ_FILTER_PLOTS";
export const GET_SIGNAL_DATA_VALIDATED = "GET_SIGNAL_DATA_VALIDATED";
export const GET_PPMSEQ_REPORT_LIST = "GET_PPMSEQ_REPORT_LIST";
export const SET_PPMSEQ_REPORT_LIST = "SET_PPMSEQ_REPORT_LIST";
export const SET_COLUMN_TYPE = "SET_COLUMN_TYPE";
