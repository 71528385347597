import React, { useEffect, useState, useRef } from 'react';
import { useTable, usePagination, useRowSelect, useFilters, useGlobalFilter, useSortBy, useExpanded, } from 'react-table';
import { useDispatch, useSelector } from "react-redux";
import MaUTable from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@mui/material/Button';
import { Row, Col, } from "react-bootstrap";
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import './table.css';
import { setRunId, setInstrumentId, setRunListDownloadData, setIsAllRunListDataShown, setIsRunDataLoading, saveAnalysisCommentData, saveDetailsData, setAnalysisCommentDataSaved, setDetailsDataSaved, setEditMode, setCustomFiltersApplied, setRunListDataFiltered, setNumberOfCustomFiltersApplied, getRunDataAllGroupedAsync, setAlert, 
    setUnifiedRowSelected, setByCameraValueGlobal, setCustomFilters, setColumnType } from "../actions/index";
import * as Filters from './filters';
import CustomFilter from '../customfilter/customfilter';
import * as Utils from '../../utils/utils';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DownloadIcon from '@mui/icons-material/Download';
import { CSVLink } from "react-csv";
import IconButton from "@material-ui/core/IconButton";
import ViewListIcon from '@mui/icons-material/ViewList';
import ManageColumns from '../managecolumns/managecolumns';
import EditIcon from '@mui/icons-material/Edit';
import CancelIcon from '@mui/icons-material/Cancel';
import SaveIcon from '@mui/icons-material/Save';
import useStickyHeader from "../../utils/hooks/useStickyHeader";
import Container from '@mui/material/Container';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import Badge from '@mui/material/Badge';
import { findAll } from "highlight-words-core";
import useDeviceQuery from '../../utils/hooks/useDeviceMedia';
import Tooltip from '@material-ui/core/Tooltip';
import CloudDoneIcon from '@mui/icons-material/CloudDone';
import CloudOffIcon from '@mui/icons-material/CloudOff';

function Table({ columns, data, handleRowClick, handleCellClick, selectedRowIndex, handleClearAdvancedSearch }) {
    const [isCustomFiltersShowing, showCustomFilters] = useState(false);
    const [isManageColumnsShowing, showManageColumns] = useState(false);

    const local_state = useSelector(state => state);

    const dispatch = useDispatch();

    const { tableRef, isSticky, isStickyRow } = useStickyHeader();
    const timeZoneMap = Utils.zoneDictionary;
    
    const getHiddenColumns = () => {
        try {
            let cols = localStorage.getItem('non-hidden-cols');
            if (cols) {
                let visible = JSON.parse(cols);
                let all = columns.map(m => m.columns).flat();
                let toHide = all.filter(f => f !== undefined).filter(function (el) {
                    return !visible.includes(el.accessor);
                });
                return toHide.map(m => m.accessor);
            }
            else return Utils.Default_Hidden_Columns;
        }
        catch (e) {
            return [];
        }
    }

    const filterTypes = React.useMemo(
        () => ({
            fuzzyText: Filters.fuzzyTextFilterFn,
            text: (rows, id, filterValue) => {
                return rows.filter(row => {
                    const rowValue = row.values[id];
                    return rowValue !== undefined
                        ? String(rowValue)
                            .toLowerCase()
                            .startsWith(String(filterValue).toLowerCase())
                        : true
                })
            },
        }),
        []
    )

    function filterGreaterThan(rows, id, filterValue) {
        return rows.filter(row => {
            const rowValue = row.values[id];
            return rowValue >= filterValue;
        })
    }

    filterGreaterThan.autoRemove = val => typeof val !== 'number';

    // const handleCellClick = (e, row) => {
    //     if (row.column.id === 'expander') {
    //         e.stopPropagation();
    //         e.preventDefault();
    //     }      
    //}

    const checkIsEditAllowed = (user) => {
        let result = false;
        try {
            if (user !== undefined && user !== null && user.roles !== undefined && user.roles.length > 0) {
                let editAllowed = user.roles.filter(f => f.name === 'AnalysisCommentEditAllowed');
                if (editAllowed && editAllowed.length) {
                    result = editAllowed.length > 0;
                }
                //result = user.signInUserSession.accessToken.payload["cognito:groups"].includes('AnalysisCommentEditAllowed');
            }
        }
        catch { }

        return result;
    }

    const defaultColumn = React.useMemo(
        () => ({
            Filter: Filters.DefaultColumnFilter,
            //Cell: EditableCell,
            minWidth: 30,
            width: 150,
            maxWidth: 400,
        }),
        []
    )

    const {
        getTableProps,
        getTableBodyProps,
        toggleHideColumn,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        allColumns,
        getToggleHideAllColumnsProps,
        getToggleRowSelectedProps,
        selectedFlatRows,
        state,
        initialState,
        visibleColumns,
        setHiddenColumns,
        preGlobalFilteredRows,
        setGlobalFilter,
    } = useTable(
        {
            columns,
            data,
            initialState: {
                pageIndex: 0,
                sortBy: [
                    {
                        id: 'startdatetime',
                        desc: true
                    }
                ],
                expanded: {},
                hiddenColumns: getHiddenColumns(),
            },
            defaultColumn,
            filterTypes,
            autoResetPage: true,
            autoResetSelectedRows: false,
            autoResetHiddenColumns: false,
            useFilters,
            useGlobalFilter,
            paginateExpandedRows: false
        },
        useFilters,
        useGlobalFilter,
        useSortBy,
        useExpanded,
        usePagination,
        useRowSelect,
    )


    React.useEffect(() => {
        try {
            let toHide = columns.filter(f => f.id === 'expander' || f.id === 'A#').map(m => m.columns).flat().filter(el => !visibleColumns.map(m => m.id).includes(el.accessor));
            toHide.map(m => toggleHideColumn(m.accessor, true));
        }
        catch { }
    }, [columns]);
    

    const handleAdvancedFilterClick = () => {
        showCustomFilters(true);
    }

    const handleSeqRunDownloadClick = (event, done) => {
        try {
            let csv = [];
            let csvColumnsAccessors = [];
            columns.forEach(m => {
                if (m.Header !== 'Expand') {
                    csvColumnsAccessors.push(...m.columns.filter(item => item.accessor !== 'timezone').map(item => item.accessor));
                    csv.push([...m.columns.filter(item => item.accessor !== 'timezone').map(item => item.accessor === 'waferShelf' ? 'Flow Cell' : item.Header)]);
                }
            });
            csv = [csv.flat()];
            data.map(m => {
                if (m.analysisstatus !== undefined && m.analysisstatus !== null && (typeof m.analysisstatus === 'string' || m.analysisstatus instanceof String)) m.analysisstatus = `"${m.analysisstatus.replace(/[\r\n]+/gm, " ").replace(/, /gm, ",")}"`;
                let result = csvColumnsAccessors.map(item => {
                    if(item === 'runid'){
                       // const value = m[item] !== undefined ? String(m[item]) : '';
                        return `=""${m[item] || ''}""`;
                    } 
                    if (item === 'details' || item === 'analysiscomment') {
                        return `${m[item]?.replace(/(\r\n|\n|\r)/gm, " ")?.replace(/['"]+/g, '') || ''}`
                    }
                    if (item === 'runstatus') {
                        return m[item][item];
                    }
                    if (item === 'rlq25') {
                        return m[item] !== undefined && m[item] !== null ? m[item].toString().split(':')[0] : '';
                    }
                    if (item === 'ber80_200') {
                        return m[item]['value_raw'] !== undefined && m[item]['value_raw'] !== null ? m[item]['value_raw'].toString().split(':')[0] : '';
                    }
                    // Merge 'timezone' value into 'startdatetime'
                    if (item === 'startdatetime') {
                        // Check if 'timezone' exists
                        const timezone = (m['timezone'] !== undefined && m['timezone'] !== null) ? timeZoneMap[local_state.time_zone_selected] : '';
                        return `${m[item]} ${timezone}`;
                    }
                    // Exclude 'timezone' value from other columns
                    if (item !== 'timezone') {
                        return m[item];
                    }
                });
                csv.push(result);
            });
            dispatch(setRunListDownloadData(csv));
            done(true);
        }
        catch (e) {
            console.log(e);
        }
    }

    const handleShowNumFlows100 = () => {
        dispatch(setIsAllRunListDataShown(!local_state.is_all_run_list_data_shown));
        dispatch(setIsRunDataLoading(true));
        dispatch(setCustomFiltersApplied(false));
        let f = Utils.getColumnFilters();
        f.analysisstatus.series = f.analysisstatus.default;
        f.analysisstatus.op = 'a';
        dispatch(setCustomFilters(f));        
        dispatch(setRunListDataFiltered([]));
        dispatch(setNumberOfCustomFiltersApplied(0));
        //!local_state.is_all_run_list_data_shown ? dispatch(getRunDataAllAsync(undefined)) : dispatch(getRunDataAllAsync(100));
        !local_state.is_all_run_list_data_shown ? dispatch(getRunDataAllGroupedAsync(undefined, local_state.jwt)) : dispatch(getRunDataAllGroupedAsync(100, local_state.jwt));
    }

    const handleManageTableClick = () => {
        showManageColumns(true);
    }

    useEffect(() => {
        try {
            if (local_state.isNumFlows100Loaded === true && local_state.is_custom_filters_applied) {
                let filters = local_state.custom_filters;
                let dataTofilter = local_state.runlist;
                let filtered = Utils.filterByCustomFilters(filters, dataTofilter, dispatch, setNumberOfCustomFiltersApplied);
                if (filtered) {
                    dispatch(setCustomFiltersApplied(true));
                    dispatch(setRunListDataFiltered(filtered));
                }
            }
        }
        catch { }
    }, [local_state.isNumFlows100Loaded]);    

    const checkUserIsLocal = (user, issubrow, row) => {
        let analysisRun = row?.values?.runid?.includes('.a') || null;
        let isLocal = Utils.isLocalRole(user);
        if (isLocal && !analysisRun) return true;
        else if (!isLocal && !issubrow) return true;
        else if (!isLocal && issubrow && !analysisRun) return false;
        return true;
    }

    const getRowActiveClassNames = row => {
        let classes = [];
        if (selectedRowIndex === row.index) classes.push('row-active');
        else if (selectedRowIndex === row.id) classes.push('row-active');
        else classes.push('not-row-active');
        if ((row.depth > 0 && row.index <= 1 && selectedRowIndex === -1) || (row.depth > 0 && row.index <= 1 && selectedRowIndex !== row.id)) classes.push('subrow');
        else if ((row.depth > 0 && row.index > 1 && selectedRowIndex === -1) || (row.depth > 0 && row.index > 1 && selectedRowIndex !== row.id)) classes.push('analysisrow');
        return classes.join(' ');
    }

    function getColumnTitle(column) {
        if (column === 'Start Date') {
          return 'Toggle SortBy. \nDate and Time of the start \nof sequencing run.';
        }
        else if (column === 'Sys Id'){
            return 'Toggle SortBy. \nUG system unique ID.';
        }
        else if (column === 'Flow Cell'){
            return 'Toggle SortBy. \nWafer number in the cartridge.';
        }
        else if (column === 'Chuck'){
            return 'Toggle SortBy. \nSlot number out of 2 on the system.';
        }
        else if (column === 'Run Id'){
            return 'Toggle SortBy. \nUnique run ID, comes from sample tube barcode.';
        }
        else if (column === 'AMP Tool'){
            return 'Toggle SortBy. \nUnique instrument id from amp runs.';
        }
        else if (column === 'Details'){
            return 'Toggle SortBy. \nUser typed comments. Comments are added during sample registration in SampleDB. \nThey also can be edited here after run is completed. ';
        }
        else if (column === 'Seq Reagents'){
            return 'Toggle SortBy. \nList of barcodes for racks, tubes, cartridges, wafers, \nkits utilized in sequencing run.';
        }
        else if (column === 'AMP Reagents'){
            return 'Toggle SortBy. \nList of barcodes for racks, tubes, cartridges, wafers, \nkits utilized in amp run.';
        }
        else if (column === 'Sequencing Progress'){
            return 'Toggle SortBy. \nStatus of the sequencing run: In Progress, Completed, Aborted. \nFor Aborted hint message provides the reason for termination.';
        }
        else if (column === 'Elapsed Time'){
            return 'Toggle SortBy. \nDuration of the sequencing run for completed or aborted runs. \nTime since start of the run for runs in progress.';
        }
        else if (column === 'Num flows'){
            return 'Toggle SortBy. \nNumber of intended flows.';
        }
        else if (column === 'Beads (M)'){
            return 'Toggle SortBy. \nTotal number of detected beads in first \nflow in Millions (10^6).';
        }
        else if (column === 'Reads PF (M)'){
            return 'Toggle SortBy. \nNumber of reads that pass quality filters.';
        }
        else if (column === 'Aligned Bases (TB)'){
            return 'Toggle SortBy. \nNumber of aligned bases in Tera Bases (10^9). \nEquals to number of pass filter reads aligned to hg19 reference times average read length.';
        }
        else if (column === 'Feasible Throughput (TB)'){
            return 'Toggle SortBy. \nNumber of post barcode flows times \n0.75bp times number of reads pass filter.';
        }
        else if (column === 'Non-sample reads (%)'){
            return 'Toggle SortBy. \nPercent of pass filter reads identified as control or with unidentified barcode.';
        }
        else if (column === 'Control MRL (bp)'){
            return 'Toggle SortBy. \nMean Read Length in control sample.';
        }
        else if (column === 'First Flow'){
            return 'Toggle SortBy. \nAverage signal across all detected beads in first flow. \nSignal is an integrated intensity of the bead minus background in gray scale.';
        }
        else if (column === 'Drop rate (%)'){
            return 'Toggle SortBy. \nAverage signal reduction per flow (aka Droop). \nCalculated as exponential fit across all flows for reads from TT barcode aligned to E.coli. \nLower value correlates with better sequencing quality.';
        }
        else if (column === 'Lag rate (%)'){
            return 'Toggle SortBy. \nLag phasing rate per incorporation. \nCalculated as exponential fit across all flows for reads from TT barcode aligned to E.coli. \nLower value correlates with better sequencing quality.';
        }
        else if (column === 'Lead rate (%)'){
            return 'Toggle SortBy. \nLead phasing rate per incorporation. \nCalculated as exponential fit across all flows for reads from TT barcode aligned to E.coli. \nLower value correlates with better sequencing quality.';
        }
        else if (column === 'Indel Rate TT (%)'){
            return 'Toggle SortBy. \nIndel Rate calculated based on UA \nalignment of reads to hg19 reference from TT barcode. ';
        }
        else if (column === 'STS'){
            return 'Toggle SortBy. \nStandard Template Score, a measure of \nseparation between signals of each homopolymer length. Well-separated (nonoverlapping) distributions receive a higher score.';
        }
        else if (column === 'R80 (%)'){
            return 'Toggle SortBy. \nPercent of (good) reads included in BER80';
        }
        else if (column === 'Ber80 @200 (%)'){
            return 'Toggle SortBy. \nBase Error Rate (BER) calculated on 80% of \nthe reads (based on RSQ selection) across first 200 bases of the read';
        }
        else if (column === 'Ber80 (%)'){
            return 'Toggle SortBy. \nBase Error Rate (BER) calculated on 80% of \nthe reads (post RSQ filtering) across entire read-length.';
        }
        else if (column === 'F95 min'){
            return 'Toggle SortBy. \nF95 at 30x for the barcode with lowest F95 \nwhen aligned to hg19 reference using UA aligner. \nBarcodes with coverage lower than 20x are excluded.';
        }
        else if (column === 'F95 max'){
            return 'Toggle SortBy. \nF95 at 30x for the barcode with highest F95 \nwhen aligned to hg19 reference using UA aligner. \nBarcodes with coverage lower than 20x are excluded.';
        }
        else if (column === 'RLQ25 (bp)'){
            return 'Toggle SortBy. \nBlack = Average read length for R80 read \nquality above Q25 (true RLQ25) Orange = Total read length (RL) of the R80 reads; \nused when the R80 read quality exceeds Q25 for \nthe entire run.';
        }
        else if (column === 'Read Length (bp)'){
            return 'Toggle SortBy. \nAverage read length of reads that \npass quality filter.';
        }
        else if (column === 'Analysis Status'){
            return 'Toggle SortBy. \nThese are 2 lights corresponding to analysis and upload \nstages of base calling. Gray means stage did not start. \nYellow means in progress. Green means completed. \nRed means aborted or errored.';
        }
        else if (column === 'Analysis Comment'){
            return 'Toggle SortBy. \nComments that can be typed in \nby user here.';
        }
        else if (column === 'Output Reads (M)'){
            return 'Toggle SortBy. \nSum of pass quality filters reads across barcodes \nwith number of reads higher than 0.1% of total reads. \nTT reads are excluded.';
        }
        else if (column === 'Monoclonal (%)'){
            return 'Toggle SortBy. \nPercent of Monoclonal Reads.';
        }
        else if (column === 'Amp Script'){
            return 'Toggle SortBy. \nAMP script version.';
        }
        
        return '';
      }

    return (
        <div className="tableContainer" id="completeTableContainer">
            <Row id="headerButtons">
                <Col>
                    <Filters.GlobalFilterMain preGlobalFilteredRows={preGlobalFilteredRows} globalFilter={state.globalFilter} setGlobalFilter={setGlobalFilter} gotoPage={gotoPage} />
                </Col>
                <Col>
                    <CustomFilter show={isCustomFiltersShowing} handleCloseModal={() => showCustomFilters(false)} data={data} />
                    <ManageColumns show={isManageColumnsShowing} handleCloseModal={() => showManageColumns(false)} getToggleHideAllColumnsProps={getToggleHideAllColumnsProps} allColumns={allColumns} visibleColumns={visibleColumns} localStorageFileName='non-hidden-cols' onReset={() => { setHiddenColumns(Utils.Default_Hidden_Columns) }} />
                    <Button id="header-advanced-filter-1" className="advanced-filter-btn" color="primary" startIcon={<Badge badgeContent={local_state.number_of_custom_filters_applied} title={local_state.number_of_custom_filters_applied !== 0 ? `${local_state.number_of_custom_filters_applied} filter(-s) applied` : 'No filters applied'}
                        color="success">{!local_state.is_custom_filters_applied ? <FilterAltOffIcon className="filter-Child" /> : <FilterAltIcon />}</Badge>} onClick={handleAdvancedFilterClick} style={{ width: '50%', color: 'white', float: 'left', boxShadow: '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)' }}>
                        Advanced Filter
                    </Button>
                </Col>
                <Col>
                    <Button id="header-advanced-filter-2" className="advanced-filter-btn" color="primary" startIcon={<DownloadIcon className="filter-Child" />} style={{ width: '30%', color: 'white', float: 'right', boxShadow: '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)' }}>
                        <CSVLink asyncOnClick={true} filename={`nexus-main-table-data.csv`} data={local_state.runlist_download_data} onClick={() => handleSeqRunDownloadClick()} style={{ color: 'white', textDecoration: 'none', }}>
                            Download
                        </CSVLink>
                    </Button>
                    <Button id="header-advanced-filter-3" className="advanced-filter-btn" color="primary" startIcon={<ViewListIcon className="filter-Child" />} style={{ width: '30%', color: 'white', float: 'right', marginRight: 10, boxShadow: '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)' }} onClick={handleManageTableClick}>Manage</Button>
                    <div id="ViewIcon">
                        <IconButton className="sequence-btn" title={local_state.is_all_run_list_data_shown ? 'Hide archived and short test runs' : 'Display archived and short test runs'} onClick={handleShowNumFlows100}
                            style={{ border: "2px solid #003049", boxShadow: '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)' }}>
                            {local_state.is_all_run_list_data_shown ? <CloudDoneIcon /> : <CloudOffIcon />}
                        </IconButton>
                    </div>
                </Col>
            </Row>
            {isSticky && local_state.show_frozen_header === true &&
                (<Container id="stickeHeaderContainer" style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    right: 0,                   
                    zIndex: 100
                }}>
                    <MaUTable {...getTableProps()} style={{ width: '99%' }} >
                        <TableHead id='stickyHeaderTableHead' style={{ width: '75%', boxShadow: '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)' }}>
                            {headerGroups.map(headerGroup => (
                                <TableRow {...headerGroup.getHeaderGroupProps()} key={Utils.generateKey('trh')}>
                                    {headerGroup.headers.map(column => (
                                        <TableCell key={Utils.generateKey('tch')} {...column.getHeaderProps(column.getSortByToggleProps(), {
                                        })}
                                            style={{ width: column.widthHeader ? column.widthHeader : column.width, minWidth: column.minWidthHeader ? column.minWidthHeader : column.minWidth, textAlign: 'center', alignContent: 'center' }}
                                            align="center" title={getColumnTitle(column.Header !== undefined ? column.Header : '')}>
                                            {column.Header !== 'Expand' ? column.render('Header') : ''}
                                            <span>
                                                {column.isSorted
                                                    ? column.isSortedDesc
                                                        ? <ExpandMoreIcon />
                                                        : <ExpandLessIcon />
                                                    : ''}
                                            </span>
                                        </TableCell>
                                    ))}
                                </TableRow>
                            ))}
                            <TableRow>
                                {headerGroups !== undefined && headerGroups.length > 0 && headerGroups[1] !== undefined && headerGroups[1].headers !== undefined && headerGroups[1].headers.map((column, i) => (
                                    <TableCell key={i} align="center" padding="none" className={!column.canFilter ? 'bg-white-non-filtered' : 'bg-white'}>
                                        {column.canFilter ? column.render('Filter') : null}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody style={selectedRowIndex !== -1 && isStickyRow ? { display: '' } : { display: 'none' }}>
                            {page.map((row, i) => {
                                if (local_state.runId !== null && local_state.runId !== undefined) {
                                    let runId = row.original.issubrow ? local_state.runId : local_state.runId.split('_')[0];
                                    if (row.original.runid === runId && !row.original.issubrow && selectedRowIndex !== -1) {
                                        prepareRow(row)
                                        return (
                                            <>
                                            <TableRow key={Utils.generateKey('trh')} {...row.getRowProps()} className='row-active' style={{ height: 50, boxShadow: '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)' }}>
                                                {row.cells.map(cell => {
                                                    return (
                                                        <TableCell key={Utils.generateKey('tch')} {...cell.getCellProps()} className={cell.column.id === 'details' || cell.column.id === 'analysiscomment' ? 'truncate-overflow-span' : undefined} style={{ width: cell.column.width, minWidth: cell.column.minWidth, textAlign: 'center', alignContent: 'center' }}>
                                                            {cell.render('Cell')}
                                                        </TableCell>
                                                    )
                                                })}
                                            </TableRow>
                                            </>
                                        )
                                    }
                                }
                            })}
                        </TableBody>
                    </MaUTable>
                </Container>)}
            <Container id="mainHeader" style={{
                margin: 0,
                width: '100%',
                maxWidth: '100%',                
            }}>
                <MaUTable {...getTableProps()} ref={tableRef} style={{ width: '100%' }} >
                    <TableHead style={{ boxShadow: '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)' }}>
                        {headerGroups.map(headerGroup => (
                            <TableRow {...headerGroup.getHeaderGroupProps()} key={Utils.generateKey('tr')}>
                                {headerGroup.headers.map(column => (
                                    <TableCell key={Utils.generateKey('tc')} {...column.getHeaderProps(column.getSortByToggleProps(), {
                                    })} style= {{minWidth: column.minWidth, width: column.width, align: "center" }}  title={getColumnTitle(column.Header !== undefined ? column.Header : '')}>
                                        {column.Header !== 'Expand' ? column.render('Header') : ''}
                                        <span>
                                            {column.isSorted
                                                ? column.isSortedDesc
                                                    ? <ExpandMoreIcon />
                                                    : <ExpandLessIcon />
                                                : ''}
                                        </span>
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                        <TableRow>
                            {headerGroups !== undefined && headerGroups.length > 0 && headerGroups[1] !== undefined && headerGroups[1].headers !== undefined && headerGroups[1].headers.map((column, i) => (
                                <TableCell key={i} align="center" padding="none" className={!column.canFilter ? 'bg-white-non-filtered' : 'bg-white'}>
                                    {column.canFilter ? column.render('Filter') : null}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody >
                        {page.map((row, i) => {
                            prepareRow(row);
                            const shouldRenderRow = (row, expandedType, originalSubRows) => {
                                try {
                                  const runid = row?.original?.runid;
                                  const issubrow = row?.original?.issubrow;
                                  // If row has no subRows, always render it
                                  if ((!originalSubRows && !issubrow) || (originalSubRows.length === 0 && !issubrow)) {
                                    return true;
                                  }
                          
                                  // If row has subRows, render based on expandedType
                                  if (issubrow && expandedType === 'analysis') {
                                    return runid?.includes('.a');
                                  } else if (issubrow && expandedType === 'camera') {
                                    return !runid?.includes('.a');
                                  } 

                          
                                  return true;
                                } catch (error) {
                                  console.log('ERROR: ', error);
                                  return false;
                                }
                              };
                              if (!shouldRenderRow(row, local_state.columnType, row.originalSubRows)) {
                                return null;
                              }

                            return (
                                <TableRow key={Utils.generateKey('tr')} {...row.getRowProps()} onClick={(e) => handleRowClick(e, row, row.originalSubRows !== undefined && row.originalSubRows.length > 0 ? row.index : row.id, selectedFlatRows)} className={getRowActiveClassNames(row)} style={checkUserIsLocal(local_state.user, row.original.issubrow, row) ? { cursor: 'pointer' } : {}}>
                                    {row.cells.map(cell => {
                                        return (
                                            <>
                                                <TableCell key={Utils.generateKey('tc')} {...cell.getCellProps()}
                                                    style={{ width: cell.column.width, minWidth: cell.column.minWidth, textAlign: 'center', alignContent: 'center', wordBreak: 'break-word' }} onClick={(e) => handleCellClick(e, cell)}>
                                                    {cell.render('Cell')}
                                                </TableCell>
                                            </>                                           
                                        )
                                    })}                       
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </MaUTable>
            </Container>
            <Row className="text-center" style={{ alignItems: 'center', marginTop: 20 }}>
                <Col lg={12} style={{ paddingBottom: 10 }}>
                    <div>
                        <Button className="advanced-filter-btn-pager" variant="contained" onClick={() => gotoPage(0)} disabled={!canPreviousPage} style={{ width: '200px', marginTop: 7, boxShadow: '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)' }}>{'<<'}</Button>{' '}
                        <Button className="advanced-filter-btn-pager" variant="contained" onClick={() => previousPage()} disabled={!canPreviousPage} style={{ width: '200px', marginTop: 7, boxShadow: '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)' }}>{'<'}</Button>{' '}
                        <span style={{ verticalAlign: 'middle' }}>
                            Page{' '}
                            <strong>
                                {state.pageIndex + 1} of {pageOptions.length}
                            </strong>{' '}
                        </span>
                        <TextField size="small" id="outlined-basic" label="Go to Page" variant="outlined" inputProps={{ type: 'number', style: { textAlign: 'center', height: 23 }, }} defaultValue={state.pageIndex + 1} style={{ width: 120, marginTop: 8, }} onChange={e => {
                            const page = e.target.value ? Number(e.target.value) - 1 : 0
                            gotoPage(page)
                        }} />
                        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                            <InputLabel id="demo-select-small">Page Size</InputLabel>
                            <Select
                                labelId="demo-select-small"
                                id="demo-select-small"
                                value={state.pageSize}
                                label="Page Size"
                                onChange={e => {
                                    setPageSize(Number(e.target.value))
                                }}
                                size="small"
                            >
                                <MenuItem value={10}>Show 10</MenuItem>
                                <MenuItem value={20}>Show 20</MenuItem>
                                <MenuItem value={30}>Show 30</MenuItem>
                                <MenuItem value={40}>Show 40</MenuItem>
                                <MenuItem value={50}>Show 50</MenuItem>
                            </Select>
                        </FormControl>
                        <Button className="advanced-filter-btn-pager" variant="contained" onClick={() => nextPage()} disabled={!canNextPage} style={{ width: '200px', marginTop: 7, boxShadow: '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)' }}>{'>'}</Button>{' '}
                        <Button className="advanced-filter-btn-pager" variant="contained" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage} style={{ width: '200px', marginTop: 7, boxShadow: '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)' }}>{'>>'}</Button>{' '}
                    </div>
                </Col>
            </Row>
        </div>
    )
}

const RenderTable = (props) => {
    const state = useSelector(state => state);
    const dispatch = useDispatch();

    const { mainTableData, handleTabsSelect, handleClearAdvancedSearch } = props;
    const [selectedRowIndex, setSelectedRowIndex] = React.useState(-1);
    const { isSmallScreen, isMediumScreen, isMedium3Screen, isBigScreen3, isBigScreen2 } = useDeviceQuery();
    const [expandedType, setExpandedType] = useState(null);

    let editRef = useRef(null);

    const getRunIdBySubRows = record => {
        if (record.subRows === undefined || record.runid.includes('.a')) return record.runid;
        let subRows = record.subRows.map(m => m.runid);
        //setting a hardcoded camera value
        return subRows.every(a => a.includes('_')) ? `${record.runid}_1` : record.runid;
    }

    const handleRowClick = (e, data, index, selectedRows) => {
        try {
            e.stopPropagation();
            e.preventDefault();
            setSelectedRowIndex(index);
            if (!data.original.runid.includes('_') && !data.original.subRows && !data.original.runid.includes('.a')) {
                let runid = getRunIdBySubRows(data.original);
                dispatch(setRunId(runid));    
                dispatch(setUnifiedRowSelected(false));
                let byCamera = Utils.getRunIdByCamera(data.original.runid);
                dispatch(setByCameraValueGlobal(`camera_${byCamera.camera}`));
                dispatch(setInstrumentId(data.original.sysid));
            }
            else if (!data.original.issubrow && !data.original.runid.includes('.a')) {
                let runid = getRunIdBySubRows(data.original);
                dispatch(setRunId(runid));    
                dispatch(setUnifiedRowSelected(true));
                dispatch(setByCameraValueGlobal('unified'));
                dispatch(setInstrumentId(data.original.sysid));
            }
            else if (data.original.issubrow && data.original.runid.includes('.a')) {
                let runid = getRunIdBySubRows(data.original);
                dispatch(setRunId(runid));    
                dispatch(setUnifiedRowSelected(true));
                dispatch(setByCameraValueGlobal('unified'));
                dispatch(setInstrumentId(data.original.sysid));
            }
            else if (Utils.isLocalRole(state.user)) {
                let byCamera = Utils.getRunIdByCamera(data.original.runid);
                dispatch(setByCameraValueGlobal(`camera_${byCamera.camera}`));
                dispatch(setRunId(data.original.runid));
                dispatch(setUnifiedRowSelected(false));
                dispatch(setInstrumentId(data.original.sysid));
            }
        }
        catch (e){
            console.log(e);
         }
    };

    useEffect(() => {
        if (state.runId !== null) {
            handleTabsSelect(state.is_unified_row_selected ? 'run_summary' : state.active_tab_name);
        }
    }, [state.runId]);

    const getRLQ25Row = rlq25 => {
        if (rlq25 !== null && rlq25 !== "undefined" && rlq25 !== "" && rlq25 !== 'inf' && rlq25 !== Infinity && rlq25 !== undefined) {
            let rawTemp = rlq25.toString().split(':');
            let colorCls = '';
            if (rawTemp.length >= 2) {
                if (rawTemp[1] === "y")
                    colorCls = 'text-dark-orange';
            }
            let v = rawTemp[0] !== null && rawTemp[0] !== undefined && rawTemp[0] !== "null" && rawTemp[0] !== "" && rawTemp[0] !== 'inf' ? parseInt(rawTemp[0]) : rawTemp[0];
            return <div className={`text-center`} title="RLQ25 (bp)">{v}</div>;
        }
        else return <div className="text-center">{''}</div>;
            
    }

    const getBer80At200Row = ber80_200 => {
        try {
            let val; 
            let rawTemp;
            if (typeof ber80_200 === 'object' && !Array.isArray(ber80_200) && ber80_200 !== null) {
                val = ber80_200.value.props.children;
                    if (val && val !== "undefined" && val !== "null" && val !== "nan") {
                        rawTemp = val.toString().split(':');
                        let v = rawTemp[0] !== null && rawTemp[0] !== undefined && rawTemp[0] !== "null" && rawTemp[0] !== "" && rawTemp[0] !== 'inf' ? parseFloat(rawTemp[0]) : rawTemp[0];
                        return <div className={`text-center`} style={{ verticalAlign: 'middle' }} title="Ber80 @200 (%)">{v}</div>;
                    } else return <div className={`text-center`} style={{ verticalAlign: 'middle' }} title="Ber80 @200 (%)">{''}</div>;
            }   
            else {
                    val = ber80_200;
                    if (val && val !== "undefined" && val !== "null" && val !== "nan") {
                        rawTemp = val.toString().split(':');
                        let v = rawTemp[0] !== null && rawTemp[0] !== undefined && rawTemp[0] !== "null" && rawTemp[0] !== "" && rawTemp[0] !== 'inf' ? parseFloat(rawTemp[0]) : rawTemp[0];
                        return <div className={`text-center`} style={{ verticalAlign: 'middle' }} title="Ber80 @200 (%)">{v}</div>;
                    } else return <div className={`text-center`} style={{ verticalAlign: 'middle' }} title="Ber80 @200 (%)">{''}</div>;
                
            }
        }
        catch {
            return <div className={`text-center`} style={{ verticalAlign: 'middle' }} title="Ber80 @200 (%)">{''}</div>;
        }
    }
    const getRefBeadsSignalRow = refbeadssig => {
        try {
            if (refbeadssig !== null && refbeadssig !== "undefined" && refbeadssig !== undefined && refbeadssig !== "" && refbeadssig !== "null") {
                let rawTemp = refbeadssig.toString().split(':');
                //return <div className={`text-center ${colorCls}`} style={{ verticalAlign: 'middle' }}>{refbeadssig !== null && refbeadssig !== "undefined" && refbeadssig !== undefined && refbeadssig !== "" && refbeadssig !== "null" ? Utils.addCommas(rawTemp[0]) : ''}</div>;
                return <div className={`text-center`} style={{ verticalAlign: 'middle' }}>{refbeadssig !== null && refbeadssig !== "undefined" && refbeadssig !== undefined && refbeadssig !== "" && refbeadssig !== "null" ? rawTemp[0].split('.')[0] : ''}</div>;
            }
            else return <div className={`text-center`} style={{ verticalAlign: 'middle' }}>{''}</div>;
        }
        catch {
            return <div className={`text-center`} style={{ verticalAlign: 'middle' }}>{''}</div>;
        }
    }

    const renderAnalysisStatus = (status, pegAbort) => {
        try {
            if(!status) return <div className={`text-center`} style={{ verticalAlign: 'middle' }}>{''}</div>;;
            //var templateClass = Utils.getAlertClass(status[0]);
            let basecallingClass = Utils.getAlertClass(status[1]);
            let chemistryClass = Utils.getAlertClass(status[2]);
            let qcAnalysisClass = Utils.getAlertClass(status[3]);
            return (
                <div id='analysisStatusDiv'>
                    <span className="text-center" style={{ textAlign: 'center', verticalAlign: 'middle' }}>                      
                        <span title={`Library analysis pipeline ${pegAbort ?  Utils.getTitleMessage('7') : Utils.getTitleMessage(status[1])}`} className={`alert_status ${pegAbort ? 'circle-red' : basecallingClass}`} style={{ marginLeft: '6px', float: 'left', }}>
                            {status[1] === 2 && <svg style={{ marginTop: 2 }} width="12" height="9" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.615234 3.8L4.70614 7L10.6146 1" stroke="white" strokeWidth="0.25" />
                            </svg>}
                        </span>
                        <span title={`Upload ${Utils.getTitleMessage(status[2])}`} className={`alert_status ${chemistryClass}`} style={{ marginLeft: 5, float: 'left', }}>
                            {status[2] === 2 && <svg style={{ marginTop: 2 }} width="12" height="9" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.615234 3.8L4.70614 7L10.6146 1" stroke="white" strokeWidth="0.25" />
                            </svg>}
                        </span>
                        {(status[3] !== '0' && status[3] !== undefined) &&
                            <span title={`QC analysis ${Utils.getTitleMessage(status[3])}`} className={`alert_status template_circle ${qcAnalysisClass}`} style={{ display: 'inline-block', marginLeft: 5, float: 'left', }}>
                                {status[3] === 2 && <svg style={{ marginTop: 2 }} width="12" height="9" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0.615234 3.8L4.70614 7L10.6146 1" stroke="white" strokeWidth="0.25" />
                                </svg>}
                            </span>
                        }
                    </span>
                </div>
            );
        } catch (error) {
            return <div className={`text-center`} style={{ verticalAlign: 'middle' }}>{''}</div>;
        }
       
    }

    const renderAnalysisStatusUnified = (status, pegAbort) => {
        try {
            if(!status) return <div className={`text-center`} style={{ verticalAlign: 'middle' }}>{''}</div>;
            //var templateClass = Utils.getAlertClass(status[0]);
            let basecallingClass = Utils.getAlertClass(status[1]);
            let chemistryClass = Utils.getAlertClass(status[2]);
            let qcAnalysisClass = Utils.getAlertClass(status[3]);
            return (
                <div id='analysisStatusDiv'>
                    <span className="text-center" style={{ textAlign: 'center', verticalAlign: 'middle' }}>                    
                        <span title={`Library analysis pipeline ${pegAbort ?  Utils.getTitleMessage('7') : Utils.getTitleMessage(status[1])}`} className={`alert_status ${pegAbort ? 'circle-red' : basecallingClass}`} style={{ marginLeft: '6px', float: 'left', }}>
                            {status[1] === 2 && <svg style={{ marginTop: 2 }} width="12" height="9" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.615234 3.8L4.70614 7L10.6146 1" stroke="white" strokeWidth="0.25" />
                            </svg>}
                        </span>
                        <span title={`Upload ${Utils.getTitleMessage(status[2])}`} className={`alert_status ${chemistryClass}`} style={{ marginLeft: 5, float: 'left', }}>
                            {status[2] === 2 && <svg style={{ marginTop: 2 }} width="12" height="9" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.615234 3.8L4.70614 7L10.6146 1" stroke="white" strokeWidth="0.25" />
                            </svg>}
                        </span>
                        {(status[3] !== '0' && status[3] !== undefined) &&
                            <span title={`QC analysis ${Utils.getTitleMessage(status[3])}`} className={`alert_status template_circle ${qcAnalysisClass}`} style={{ display: 'inline-block', marginLeft: 5, float: 'left', }}>
                                {status[3] === 2 && <svg style={{ marginTop: 2 }} width="12" height="9" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0.615234 3.8L4.70614 7L10.6146 1" stroke="white" strokeWidth="0.25" />
                                </svg>}
                            </span>}
                    </span>
                </div>
            );
        } catch (error) {
            return <div className={`text-center`} style={{ verticalAlign: 'middle' }}>{''}</div>;
        }
    }

    const mergeAnalysisStatus = statuses => {
        try {
            if (statuses !== undefined && statuses.length >= 2) {
                let status1 = statuses[0];
                let status2 = statuses[1];
                let result = [];
                if (status1 !== undefined && status1 !== null && status1.length >= 4 && status2 !== undefined && status2 !== null && status2.length >= 4) {
                    status1.split('').forEach((item, i) => {
                        if (item > '3') item = '3';
                        if (status2[i] > '3') status2[i] = '3';
                        if (item === '0') {
                            if(status2[i] === '0') result.push(0);
                            else if(status2[i] === '1') result.push(1);
                            else if(status2[i] === '2') result.push(1);
                            else if(status2[i] === '3') result.push(3);
                        }
                        else if (item === '1') {
                            if(status2[i] === '0') result.push(1);
                            else if(status2[i] === '1') result.push(1);
                            else if(status2[i] === '2') result.push(1);
                            else if(status2[i] === '3') result.push(3);
                        }
                        else if (item === '2'){
                            if(status2[i] === '0') result.push(1);
                            else if(status2[i] === '1') result.push(1);
                            else if(status2[i] === '2') result.push(2);
                            else if(status2[i] === '3') result.push(3);
                        }
                        else if (item === '3' ) {
                            if(status2[i] === '0') result.push(3);
                            else if(status2[i] === '1') result.push(3);
                            else if(status2[i] === '2') result.push(3);
                            else if(status2[i] === '3') result.push(3);
                        }
                    });

                    return result.join('');
                }
                else return '0000';
            }
        }
        catch (e) {
            return '0000';
        }
    }

    const getAnalysisStatus = (row) => {
        try {
            if (row !== null && row !== "undefined" && row !== undefined && row !== "" && row.row !== undefined && row.row.original !== undefined) {
                let subRows = row.row.original.subRows;
                let pegAbort = false;
                if (subRows && subRows.length) {
                    let statuses = subRows.map(f => f.analysisstatus);
                    let status1 = statuses[0];
                    let status2 = statuses[1];
                    if(status1?.includes('6')) {
                        status1 = '0200';
                        pegAbort = true;
                    }
                    if(status2?.includes('6')){
                        status2 = '0200';
                        pegAbort = true;
                    }
                    let finalStat = [status1, status2]
                    let status = mergeAnalysisStatus(finalStat);
                    let rowValue = row.value ? row.value : null;
                    if (status !== undefined) return renderAnalysisStatusUnified(status, pegAbort)
                    else {
                        let v = rowValue;
                        if(v?.includes(6)){
                            v = '0200';
                            pegAbort = true;
                        }
                        return renderAnalysisStatus(v, pegAbort);
                    }
                }
                else {
                    let v = row.value;
                    if(v?.includes(6)){
                        v = '0200';
                        pegAbort = true;
                    }
                    if (v !== null && v !== "null" && v !== "undefined" && v !== undefined && v !== "") {
                        if (v.length > 0 && !isNaN(v)) {
                            return renderAnalysisStatus(v, pegAbort);
                        }
                        else {
                            return <td className="text-center">N/A</td>;
                        }
                    }
                    else if (v === null) {
                        v = "0000";
                        let status = v.toString();                        
                        let basecallingClass = Utils.getAlertClass(status[1]);
                        let chemistryClass = Utils.getAlertClass(status[2]);                       
                        return (
                            <div id='analysisStatusDiv'>
                                <span className="" style={{ textAlign: 'center' }}>                                   
                                    <span title={`Library analysis pipeline ${Utils.getTitleMessage(status[1])}`} className={`alert_status ${basecallingClass}`} style={{ marginLeft: 6, float: 'left', }}></span>
                                    <span title={`Upload ${Utils.getTitleMessage(status[2])}`} className={`alert_status ${chemistryClass}`} style={{ marginLeft: 5, float: 'left', }}></span>                                    
                                </span>
                            </div>
                        );
                    }
                    else return <td className="text-center">{row.value}</td>;
                }
            }
            else return <td className="text-center">{row.value}</td>;
        }
        catch (e) {
            return <td className="text-center">{row.value}</td>;
        }
    }

    const getF95Row = f95 => {
        if (f95 !== null && f95 !== "undefined" && f95 !== "" && f95 !== 'inf' && f95 !== Infinity && f95 !== undefined) {
            let rawTemp = f95.toString().split(':');            
            let vv = rawTemp[0] !== null && rawTemp[0] !== undefined && rawTemp[0] !== "null" && rawTemp[0] !== "" && rawTemp[0] !== 'nan' && rawTemp[0] !== 'inf' && rawTemp[0] !== "inf" ? rawTemp[0] : rawTemp[0] !== 'nan' ? rawTemp[0] : '';
            return <div className={`text-center`} style={{ verticalAlign: 'middle' }} title="F95 @30x">{vv}</div>;
        }
        else return <div className="text-center" style={{ verticalAlign: 'middle' }}>{''}</div>;
    }

    function compareNumericString(rowA, rowB, id, desc) {
        try {
        let a = Number.parseFloat(rowA.values[id][id]);
        let b = Number.parseFloat(rowB.values[id][id]);
        if (Number.isNaN(a)) a = desc ? Number.NEGATIVE_INFINITY : Number.POSITIVE_INFINITY;
        if (Number.isNaN(b)) b = desc ? Number.NEGATIVE_INFINITY : Number.POSITIVE_INFINITY;
        if (a > b) return 1;
        if (a < b) return -1;
        return 0;
        } catch (error) {
            console.log(error)
        }
    }

    const checkColumnIsHidden = name => {
        try {
            let cols = localStorage.getItem('non-hidden-cols');
            if (cols) {
                let visible = JSON.parse(cols);
                let s = visible.filter(f => f === name);
                if (s !== undefined && s.length > 0) return false
                else return true;
            }
            else return false;
        }
        catch (e) {
            return false;
        }
    }

    const handleCellClick = (e, row) => {
        if (row.column.id === 'A#') {
            e.stopPropagation();
            e.preventDefault();
            if(state.columnType !== null){
                dispatch(setColumnType(null));
            } else {
                dispatch(setColumnType('analysis'));
            }
        } else if (row.column.id === 'expander') {
            e.stopPropagation();
            e.preventDefault();
            if(state.columnType !== null){
                dispatch(setColumnType(null));
            } else {
                dispatch(setColumnType('camera'));
            }
        }
    };
    
    const getAnalysisMetaDataTime = row => {
        // Check if row and its properties exist
        if (!row || !row.row || !row.row.original || !row.row.original.subRows) {
            return null;
        }
        // Safely access subRows
        const subRows = row.row.original.subRows;
    
        // Safely get cam1 and cam2 values
        let cam1 = subRows[0] && subRows[0].analysisMetaDataTime;
        let cam2 = subRows[1] && subRows[1].analysisMetaDataTime;
        let timezone = row.row.original.timezone && row.row.original.timezone !== '' ? row.row.original.timezone : process.env.REACT_APP_TIMEZONE;
        // If both cam1 and cam2 are undefined, return null
        if (cam1 === undefined && cam2 === undefined) {
            return null;
        }
    
        // Call the timeDifferenceWithin48Hours function
        let result = Utils.timeDifferenceWithin48Hours(cam1, cam2, timezone);
        return result;
    }


    const displayValueByAnalysisOrCamera = (row, accessor) => {
        try {
            if (row.row.original.issubrow) {
                const alwaysShowColumns = ['control_mrl', 'non_sample_reads', 'pf_output_reads', 'aligned_bases', 'num_reads_pass_filter', 'indel_rate', 'f95_30x_min', 'f95_30x_max', 'rlq25', 'rl', 'analysisMetaDataTime'];
                if(accessor === 'analysisstatus' || accessor === 'analysiscomment') {
                    return row;
                }
                else if ((accessor === 'numbeads' || accessor === 'firsttsig') && !row.row.original.runid.includes('.a')) return undefined;
                else if (row.row.original.runid.includes('.a')) {
                    if (alwaysShowColumns.includes(accessor)) {
                        return undefined;
                    }
                }
                return row;
            }
            return row;
        } catch (error) {
            console.log(error);
            return row;
        }
    };
    

    const getTableColumns = user => {
        let cols = [
            {
                Header: 'Run Info',
                columns: [
                    {
                        Header: 'Sequencing Start Date',
                        accessor: 'startdatetime',
                        width: isBigScreen3 ? (isMedium3Screen ? 60 : 70) : 100,
                        widthHeader: isBigScreen3 ? (isMedium3Screen ? 60 : 70) : 100,
                        minWidth: isBigScreen3 ? (isMedium3Screen ? 60 : 70) : 100,
                        minWidthHeader: isBigScreen3 ? (isMedium3Screen ? 60 : 70) : 100,
                        show: true,
                        disableGlobalFilter: checkColumnIsHidden('startdatetime'),
                        sortType: (a, b, id, desc) => {
                            //return new Date(a.values.startdatetime) - new Date(b.values.startdatetime);                           
                            if (new Date(a.values.startdatetime) < new Date(b.values.startdatetime)) {
                                return -1;
                            } else if (new Date(a.values.startdatetime) > new Date(b.values.startdatetime)) {
                                return 1;
                            };

                            return 0;
                        },
                        //Cell: row => <div className='text-center'>{row.value}</div>
                        Cell: row => renderTableCell(row, row.value, 'startdatetime', false, 'Start Date'),
                    },
                    {
                        Header: 'Sys Id',
                        accessor: 'sysid',
                        width: isBigScreen3 ? (isMedium3Screen ? 55 : 60) : 80,
                        widthHeader: isBigScreen3 ? (isMedium3Screen ? 55 : 60) : 80,
                        minWidth: isBigScreen3 ? (isMedium3Screen ? 45 : 50) : 70,
                        minWidthHeader: isBigScreen3 ? (isMedium3Screen ? 45 : 50) : 70,
                        show: true,
                        disableGlobalFilter: checkColumnIsHidden('sysid'),
                        Cell: row => renderTableCell(row, row.value, 'sysid', false, 'Sys Id'),
                    },
                    {
                        Header: 'Flow Cell',
                        accessor: 'wafershelf',
                        width: isBigScreen3 ? (isMedium3Screen ? 30 : 50) : 70,
                        widthHeader: isBigScreen3 ? (isMedium3Screen ? 30 : 50) : 70,
                        minWidth: isBigScreen3 ? (isMedium3Screen ? 20 : 25) : 40,
                        minWidthHeader: isBigScreen3 ? (isMedium3Screen ? 20 : 25) : 40,
                        show: true,
                        disableGlobalFilter: checkColumnIsHidden('wafershelf'),
                        Cell: row => renderTableCell(row, row.value, 'wafershelf', false, 'Flow Cell'),
                    },
                    {
                        Header: 'Chuck',
                        accessor: 'chuck',
                        width: isBigScreen3 ? (isMedium3Screen ? 30 : 50) : 70,
                        widthHeader: isBigScreen3 ? (isMedium3Screen ? 30 : 50) : 70,
                        minWidth: isBigScreen3 ? (isMedium3Screen ? 20 : 25) : 40,
                        minWidthHeader: isBigScreen3 ? (isMedium3Screen ? 20 : 25) : 40,
                        show: false,
                        disableGlobalFilter: checkColumnIsHidden('chuck'),
                        Cell: row => renderTableCell(row, row.value, 'chuck', false, 'Chuck'),
                    },
                    {
                        Header: 'Run Id',
                        accessor: 'runid',
                        width: isBigScreen3 ? (isMedium3Screen ? 75 : 90) : 90,
                        widthHeader: isBigScreen3 ? (isMedium3Screen ? 75 : 90) : 90,
                        minWidth: isBigScreen3 ? (isMedium3Screen ? 45 : 70) : 80,
                        minWidthHeader: isBigScreen3 ? (isMedium3Screen ? 45 : 70) : 80,
                        show: true,
                        disableGlobalFilter: checkColumnIsHidden('runid'),
                        Cell: row => renderTableCell(undefined, row.value, 'runid', false, 'Run Id', row.row.original.isanalysisrow),
                    },
                    {
                        Header: 'AMP Tool',
                        accessor: 'instrumentId',
                        width: isBigScreen3 ? (isMedium3Screen ? 55 : 60) : 70,
                        widthHeader: isBigScreen3 ? (isMedium3Screen ? 60 : 65) : 70,
                        minWidth: isBigScreen3 ? (isMedium3Screen ? 20 : 25) : 40,
                        minWidthHeader: isBigScreen3 ? (isMedium3Screen ? 20 : 25) : 40,
                        show: false,
                        disableGlobalFilter: checkColumnIsHidden('instrumentId'),
                        Cell: row => renderTableCell(row, row.value, 'instrumentId', false, 'AMP Tool'),
                    },
                    {
                        Header: 'Details',
                        accessor: 'details',
                        widthHeader: isBigScreen3 ? (isMedium3Screen ? 190 : 200) : (isBigScreen2 ? 300 : 400),
                        width: isBigScreen3 ? (isMedium3Screen ? 180 : 200) : (isBigScreen2 ? 300 : 400),
                        minWidth: isBigScreen3 ? (isMedium3Screen ? 170 : 180) : (isBigScreen2 ? 220 : 380),
                        minWidthHeader: isBigScreen3 ? (isMedium3Screen ? 170 : 190) : (isBigScreen2 ? 220 : 380),
                        show: true,
                        disableGlobalFilter: checkColumnIsHidden('details'),
                        Cell: row => EditableCell(displayValueByAnalysisOrCamera(row, 'details'), editRef),
                    },
                    {
                        Header: 'Seq Reagents',
                        accessor: 'reagents',
                        minWidth: isBigScreen3 ? (isMedium3Screen ? 45 : 70) : 80,
                        minWidthHeader: isBigScreen3 ? (isMedium3Screen ? 45 : 70) : 80,
                        show: false,
                        disableGlobalFilter: checkColumnIsHidden('reagents'),
                        Cell: row => renderTableCell(row, row.value, 'reagents', false, 'Seq Reagents'),
                    },
                    {
                        Header: 'AMP Reagents',
                        accessor: 'amp_reagents',
                        minWidth: isBigScreen3 ? (isMedium3Screen ? 45 : 70) : 80,
                        minWidthHeader: isBigScreen3 ? (isMedium3Screen ? 45 : 70) : 80,
                        show: false,
                        disableGlobalFilter: checkColumnIsHidden('amp_reagents'),
                        Cell: row => renderTableCell(row, row.value, 'amp_reagents', false, 'AMP Reagents'),
                    },
                    {
                        Header: 'Amp Script',
                        accessor: 'amp_script_version',
                        minWidth: isBigScreen3 ? (isMedium3Screen ? 35 : 50) : 60,
                        minWidthHeader: isBigScreen3 ? (isMedium3Screen ? 40 : 45) : 50,
                        show: false,
                        disableGlobalFilter: checkColumnIsHidden('amp_script_version'),
                        Cell: row => renderTableCell(row, row.value, 'amp_script_version', false, 'Amp Script'),
                    },
                ],
            },
            {
                Header: 'Run Stats',
                columns: [
                    {
                        Header: 'Sequencing Progress',
                        accessor: 'runstatus',
                        width: isBigScreen3 ? (isMedium3Screen ? 45 : 50) : 65,
                        widthHeader: isBigScreen3 ? (isMedium3Screen ? 45 : 50) : 65,
                        minWidth: isBigScreen3 ? (isMedium3Screen ? 40 : 50) : 60,
                        minWidthHeader: isBigScreen3 ? (isMedium3Screen ? 40 : 50) : 60,
                        //Filter: Filters.NumberRangeColumnFilter,
                        //filter: 'between',
                        Filter: () => (
                            <input
                                className='disabledFilter'
                                style={{
                                    border: 'none',
                                    borderBottomStyle: 'solid',
                                    borderWidth: '0.5px',
                                    borderBottomColor: 'rgb(118, 118, 118)',
                                    width: '100%',
                                    backgroundColor: 'white',
                                    color: 'white'
                                }}
                                disabled='disabled'
                            />
                        ),
                        sortType: compareNumericString, // custom function
                        show: true,
                        disableGlobalFilter: checkColumnIsHidden('runstatus'),
                        Cell: row => renderTableCell(displayValueByAnalysisOrCamera(row, 'runstatus'), Utils.getRunStatus(row.value), 'runstatus', false, 'Sequencing Progress'),
                    },
                    {
                        Header: 'Elapsed Time',
                        accessor: 'runtime',
                        width: isBigScreen3 ? (isMedium3Screen ? 55 : 60) : 70,
                        widthHeader: isBigScreen3 ? (isMedium3Screen ? 60 : 65) : 70,
                        minWidth: isBigScreen3 ? (isMedium3Screen ? 35 : 40) : 65,
                        minWidthHeader: isBigScreen3 ? (isMedium3Screen ? 35 : 40) : 65,
                        show: true,
                        disableGlobalFilter: checkColumnIsHidden('runtime'),
                        Cell: row => renderTableCell(displayValueByAnalysisOrCamera(row, 'runtime'), row.value, 'runtime', false, 'Elapsed Time'),
                    },
                    {
                        Header: 'Num flows',
                        accessor: 'numflows',
                        width: isBigScreen3 ? (isMedium3Screen ? 55 : 60) : 70,
                        widthHeader: isBigScreen3 ? (isMedium3Screen ? 60 : 65) : 70,
                        minWidth: isBigScreen3 ? (isMedium3Screen ? 35 : 40) : 65,
                        minWidthHeader: isBigScreen3 ? (isMedium3Screen ? 35 : 40) : 65,
                        show: true,
                        disableGlobalFilter: checkColumnIsHidden('numflows'),
                        Cell: row => renderTableCell(displayValueByAnalysisOrCamera(row, 'numflows'), row.value, 'numflows', false, 'Num flows'),
                    },
                    {
                        Header: 'Beads (M)',
                        accessor: 'numbeads',
                        width: isBigScreen3 ? (isMedium3Screen ? 55 : 60) : 70,
                        widthHeader: isBigScreen3 ? (isMedium3Screen ? 60 : 65) : 70,
                        minWidth: isBigScreen3 ? (isMedium3Screen ? 35 : 40) : 65,
                        minWidthHeader: isBigScreen3 ? (isMedium3Screen ? 35 : 40) : 65,
                        show: true,
                        disableGlobalFilter: checkColumnIsHidden('numbeads'),
                        Cell: row => renderTableCell(displayValueByAnalysisOrCamera(row, 'numbeads'), row.value, 'numbeads', false, 'Beads (M)'),
                    },
                    {
                        Header: 'Reads PF (M)',
                        accessor: 'num_reads_pass_filter',
                        minWidth: isBigScreen3 ? (isMedium3Screen ? 35 : 40) : 60,
                        minWidthHeader: isBigScreen3 ? (isMedium3Screen ? 35 : 40) : 60,
                        //isHiddenByDefault: true,
                        show: true,
                        disableGlobalFilter: checkColumnIsHidden('num_reads_pass_filter'),
                        Cell: row => renderTableCell(displayValueByAnalysisOrCamera(row, 'num_reads_pass_filter'), Utils.tryParseInt(row.value, row.value), 'num_reads_pass_filter', false, 'Reads PF (M)'),
                    },
                    {
                        Header: 'Aligned Bases (TB)',
                        accessor: 'aligned_bases',
                        minWidth: isBigScreen3 ? (isMedium3Screen ? 35 : 40) : 60,
                        minWidthHeader: isBigScreen3 ? (isMedium3Screen ? 35 : 40) : 60,
                        //isHiddenByDefault: true,
                        show: true,
                        disableGlobalFilter: checkColumnIsHidden('aligned_bases'),
                        Cell: row => renderTableCell(displayValueByAnalysisOrCamera(row, 'aligned_bases'), row.value, 'aligned_bases', false, 'Aligned Bases (TB)'),
                    },
                    {
                        Header: 'Output Reads (M)',
                        accessor: 'pf_output_reads',
                        width: isBigScreen3 ? (isMedium3Screen ? 55 : 60) : 70,
                        widthHeader: isBigScreen3 ? (isMedium3Screen ? 60 : 65) : 70,
                        minWidth: isBigScreen3 ? (isMedium3Screen ? 35 : 40) : 65,
                        minWidthHeader: isBigScreen3 ? (isMedium3Screen ? 35 : 40) : 65,      
                        Cell: row => renderTableCell(displayValueByAnalysisOrCamera(row, 'pf_output_reads'), row.value, 'pf_output_reads', false, 'Output Reads (M)'),
                    },
                    {
                        Header: 'Feasible Throughput (TB)',
                        accessor: 'feasible_throughput',
                        minWidth: isBigScreen3 ? (isMedium3Screen ? 35 : 40) : 60,
                        minWidthHeader: isBigScreen3 ? (isMedium3Screen ? 35 : 40) : 60,
                        //isHiddenByDefault: true,
                        show: true,
                        disableGlobalFilter: checkColumnIsHidden('feasible_throughput'),
                        Cell: row => renderTableCell(displayValueByAnalysisOrCamera(row, 'feasible_throughput'), row.value, 'feasible_throughput', false, 'Feasible Throughput (TB)'),
                    },
                    {
                        Header: 'Non-sample reads (%)',
                        accessor: 'non_sample_reads',
                        width: isBigScreen3 ? (isMedium3Screen ? 55 : 60) : 70,
                        widthHeader: isBigScreen3 ? (isMedium3Screen ? 60 : 65) : 70,
                        minWidth: isBigScreen3 ? (isMedium3Screen ? 35 : 40) : 65,
                        minWidthHeader: isBigScreen3 ? (isMedium3Screen ? 35 : 40) : 65,   
                        show: true,
                        disableGlobalFilter: checkColumnIsHidden('non_sample_reads'),   
                        Cell: row => renderTableCell(displayValueByAnalysisOrCamera(row, 'non_sample_reads'), row.value, 'non_sample_reads', false, 'Non-sample reads (%)'),
                    },
                    {
                        Header: 'Control MRL (bp)',
                        accessor: 'control_mrl',
                        width: isBigScreen3 ? (isMedium3Screen ? 55 : 60) : 70,
                        widthHeader: isBigScreen3 ? (isMedium3Screen ? 60 : 65) : 70,
                        minWidth: isBigScreen3 ? (isMedium3Screen ? 35 : 40) : 65,
                        minWidthHeader: isBigScreen3 ? (isMedium3Screen ? 35 : 40) : 65,   
                        //isHiddenByDefault: true,
                        show: true,
                        disableGlobalFilter: checkColumnIsHidden('control_mrl'),
                        Cell: row => renderTableCell(displayValueByAnalysisOrCamera(row, 'control_mrl'), row.value, 'control_mrl', false, 'Control MRL (bp)'),
                    },
                ],
            },
            {
                Header: 'Signal',
                columns: [
                    /*{
                        Header: 'Ref Bead',
                        accessor: 'refbeadssig',
                        //Cell: row => getRefBeadsSignalRow(row.value),
                        Cell: row => renderTableCell(getRefBeadsSignalRow(row.value), 'refbeadssig'),
                    },*/
                    {
                        Header: 'First Flow',
                        accessor: 'firsttsig',
                        width: isBigScreen3 ? (isMedium3Screen ? 55 : 60) : 70,
                        widthHeader: isBigScreen3 ? (isMedium3Screen ? 60 : 65) : 70,
                        minWidth: isBigScreen3 ? (isMedium3Screen ? 35 : 40) : 65,
                        minWidthHeader: isBigScreen3 ? (isMedium3Screen ? 35 : 40) : 65,
                        show: true,
                        disableGlobalFilter: checkColumnIsHidden('firsttsig'),
                        //Cell: row => renderTableCell(getRefBeadsSignalRow(Utils.addCommas(row.value)), 'firsttsig'),
                        Cell: row => renderTableCell(displayValueByAnalysisOrCamera(row, 'firsttsig'), getRefBeadsSignalRow(row.value), 'firsttsig', false, 'First Flow'),
                    },
                    {
                        Header: 'Drop rate (%)',
                        accessor: 'droop',
                        width: isBigScreen3 ? (isMedium3Screen ? 55 : 60) : 70,
                        widthHeader: isBigScreen3 ? (isMedium3Screen ? 60 : 65) : 70,
                        minWidth: isBigScreen3 ? (isMedium3Screen ? 35 : 40) : 65,
                        minWidthHeader: isBigScreen3 ? (isMedium3Screen ? 35 : 40) : 65,
                        show: true,
                        disableGlobalFilter: checkColumnIsHidden('droop'),
                        Cell: row => renderTableCell(undefined, row.value, 'droop', false, 'Drop rate (%)'),
                    },
                ],
            },
            {
                Header: 'Phasing',
                columns: [
                    {
                        Header: 'Lag rate (%)',
                        accessor: 'lag',
                        width: isBigScreen3 ? (isMedium3Screen ? 55 : 60) : 70,
                        widthHeader: isBigScreen3 ? (isMedium3Screen ? 60 : 65) : 70,
                        minWidth: isBigScreen3 ? (isMedium3Screen ? 35 : 40) : 65,
                        minWidthHeader: isBigScreen3 ? (isMedium3Screen ? 35 : 40) : 65,
                        show: true,
                        disableGlobalFilter: checkColumnIsHidden('lag'),
                        Cell: row => renderTableCell(undefined, row.value, 'lag', false, 'Lag rate (%)'),
                    },
                    {
                        Header: 'Lead rate (%)',
                        accessor: 'lead',
                        width: isBigScreen3 ? (isMedium3Screen ? 55 : 60) : 70,
                        widthHeader: isBigScreen3 ? (isMedium3Screen ? 60 : 65) : 70,
                        minWidth: isBigScreen3 ? (isMedium3Screen ? 35 : 40) : 65,
                        minWidthHeader: isBigScreen3 ? (isMedium3Screen ? 35 : 40) : 65,
                        show: true,
                        disableGlobalFilter: checkColumnIsHidden('lead'),
                        Cell: row => renderTableCell(undefined, row.value, 'lead', false, 'Lead rate (%)'),
                    }
                ],
            },
            {
                Header: 'Accuracy',
                columns: [
                    {
                        Header: 'Indel Rate TT (%)',
                        accessor: 'indel_rate',
                        width: isBigScreen3 ? (isMedium3Screen ? 55 : 60) : 70,
                        widthHeader: isBigScreen3 ? (isMedium3Screen ? 60 : 65) : 70,
                        minWidth: isBigScreen3 ? (isMedium3Screen ? 35 : 40) : 65,
                        minWidthHeader: isBigScreen3 ? (isMedium3Screen ? 35 : 40) : 65,
                        show: true,
                        disableGlobalFilter: checkColumnIsHidden('indel_rate'),
                        Cell: row => renderTableCell(displayValueByAnalysisOrCamera(row, 'indel_rate'), row.value, 'indel_rate', false, 'Indel Rate TT (%)'),
                    },
                    {
                        Header: 'STS',
                        accessor: 'sts',
                        minWidth: isBigScreen3 ? (isMedium3Screen ? 35 : 40) : 60,
                        minWidthHeader: isBigScreen3 ? (isMedium3Screen ? 35 : 40) : 60,
                        isHiddenByDefault: true,
                        show: true,
                        disableGlobalFilter: checkColumnIsHidden('sts'),
                        Cell: row => renderTableCell(undefined, row.value, 'sts', false, 'STS'),
                    },
                    {
                        Header: 'R80 (%)',
                        accessor: 'r80',
                        minWidth: isBigScreen3 ? (isMedium3Screen ? 35 : 40) : 60,
                        minWidthHeader: isBigScreen3 ? (isMedium3Screen ? 35 : 40) : 60,
                        //isHiddenByDefault: true,
                        show: false,
                        disableGlobalFilter: checkColumnIsHidden('r80'),
                        Cell: row => renderTableCell(undefined, row.value, 'r80', false, 'R80 (%)'),
                    },
                    {
                        Header: 'Ber80 @200 (%)',
                        accessor: 'ber80_200',
                        minWidth: isBigScreen3 ? (isMedium3Screen ? 35 : 40) : 60,
                        minWidthHeader: isBigScreen3 ? (isMedium3Screen ? 35 : 40) : 60,
                        //isHiddenByDefault: true,
                        show: false,
                        disableGlobalFilter: checkColumnIsHidden('ber80_200'),
                        Cell: row => renderTableCell(undefined, getBer80At200Row(row.value), 'ber80_200', false, 'Ber80 @200 (%)'),
                    },
                    {
                        Header: 'Ber80 (%)',
                        accessor: 'ber80',
                        minWidth: isBigScreen3 ? (isMedium3Screen ? 35 : 40) : 60,
                        minWidthHeader: isBigScreen3 ? (isMedium3Screen ? 35 : 40) : 60,
                        //isHiddenByDefault: true,
                        show: false,
                        disableGlobalFilter: checkColumnIsHidden('ber80'),
                        Cell: row => renderTableCell(undefined, row.value, 'ber80', false, 'Ber80 (%)'),
                    },
                ],
            },
            {
                Header: 'Library Quality',
                columns: [
                    {
                        Header: 'F95 min',
                        accessor: 'f95_30x_min',
                        //disableFilters: true,
                        show: true,
                        disableGlobalFilter: checkColumnIsHidden('f95_30x_min'),
                        width: isBigScreen3 ? (isMedium3Screen ? 55 : 60) : 70,
                        widthHeader: isBigScreen3 ? (isMedium3Screen ? 60 : 65) : 70,
                        minWidth: isBigScreen3 ? (isMedium3Screen ? 35 : 40) : 65,
                        minWidthHeader: isBigScreen3 ? (isMedium3Screen ? 35 : 40) : 65,
                        Filter: () => (
                            <input
                                className='disabledFilter'
                                style={{
                                    border: 'none',
                                    borderBottomStyle: 'solid',
                                    borderWidth: '0.5px',
                                    borderBottomColor: 'rgb(118, 118, 118)',
                                    width: '100%',
                                    backgroundColor: 'white',
                                    color: 'white'
                                }}
                                disabled='disabled'
                            />
                        ),
                        Cell: row => renderTableCell(displayValueByAnalysisOrCamera(row, 'f95_30x_min'), getF95Row(row.value), 'f95_30x_min', false, 'F95 min'),
                    },
                    {
                        Header: 'F95 max',
                        accessor: 'f95_30x_max',
                        width: isBigScreen3 ? (isMedium3Screen ? 55 : 60) : 70,
                        widthHeader: isBigScreen3 ? (isMedium3Screen ? 60 : 65) : 70,
                        minWidth: isBigScreen3 ? (isMedium3Screen ? 35 : 40) : 65,
                        minWidthHeader: isBigScreen3 ? (isMedium3Screen ? 35 : 40) : 65,
                        //disableFilters: true,
                        show: true,
                        disableGlobalFilter: checkColumnIsHidden('f95_30x_max'),
                        Filter: () => (
                            <input
                                className='disabledFilter'
                                style={{
                                    border: 'none',
                                    borderBottomStyle: 'solid',
                                    borderWidth: 'thin',
                                    borderBottomColor: 'rgb(118, 118, 118)',
                                    width: '100%',
                                    backgroundColor: 'white',
                                    color: 'white',

                                }}
                                disabled='disabled'
                            />
                        ),
                        Cell: row => renderTableCell(displayValueByAnalysisOrCamera(row, 'f95_30x_max'), getF95Row(row.value), 'f95_30x_max', false, 'F95 max'),
                    },
                    {
                        Header: 'RLQ25 (bp)',
                        accessor: 'rlq25',
                        minWidth: isBigScreen3 ? (isMedium3Screen ? 35 : 40) : 60,
                        minWidthHeader: isBigScreen3 ? (isMedium3Screen ? 35 : 40) : 60,
                        //isHiddenByDefault: true,
                        show: false,
                        disableGlobalFilter: checkColumnIsHidden('rlq25'),
                        Cell: row => renderTableCell(displayValueByAnalysisOrCamera(row, 'rlq25'), getRLQ25Row(row.value), 'rlq25', false, 'RLQ25 (bp)'),
                    },
                    {
                        Header: 'Read Length (bp)',
                        accessor: 'rl',
                        width: isBigScreen3 ? (isMedium3Screen ? 55 : 60) : 70,
                        widthHeader: isBigScreen3 ? (isMedium3Screen ? 60 : 65) : 70,
                        minWidth: isBigScreen3 ? (isMedium3Screen ? 35 : 40) : 65,
                        minWidthHeader: isBigScreen3 ? (isMedium3Screen ? 35 : 40) : 65,
                        //isHiddenByDefault: true,
                        show: true,
                        disableGlobalFilter: checkColumnIsHidden('rl'),
                        Cell: row => renderTableCell(displayValueByAnalysisOrCamera(row, 'rl'), row.value, 'rl', false, 'Read Length (bp)'),
                    },
                    {
                        Header: 'Monoclonal (%)',
                        accessor: 'monoclonal_perc',
                        minWidth: isBigScreen3 ? (isMedium3Screen ? 35 : 40) : 60,
                        minWidthHeader: isBigScreen3 ? (isMedium3Screen ? 35 : 40) : 60,
                        //isHiddenByDefault: true,
                        show: false,
                        disableGlobalFilter: checkColumnIsHidden('monoclonal_perc'),
                        Cell: row => renderTableCell(undefined, row.value, 'monoclonal_perc', false, 'Monoclonal (%)'),
                    },
                ],
            },
            {
                Header: 'Analysis',
                columns: [
                    {
                        Header: 'Analysis Status',
                        accessor: 'analysisstatus',
                        Cell: row => getAnalysisStatus(displayValueByAnalysisOrCamera(row, 'analysisstatus')),
                        width: isBigScreen3 ? (isMedium3Screen ? 80 : 90) : 100,
                        widthHeader: isBigScreen3 ? (isMedium3Screen ? 80 : 90) : 100,
                        minWidth: isBigScreen3 ? (isMedium3Screen ? 70 : 80) : 90,
                        minWidthHeader: isBigScreen3 ? (isMedium3Screen ? 70 : 80) : 90,
                        //disableFilters: true,
                        show: true,
                        Filter: () => (
                            <input
                                className='disabledFilter'
                                style={{
                                    border: 'none',
                                    borderBottomStyle: 'solid',
                                    borderWidth: '1px',
                                    borderBottomColor: 'rgb(118, 118, 118)',
                                    width: '100%',
                                    backgroundColor: 'white',
                                    color: 'white',

                                }}
                                disabled='disabled'
                            />
                        ),
                    },
                    {
                        Header: 'Elapsed Time',
                        accessor: 'analysisMetaDataTime',
                        width: isBigScreen3 ? (isMedium3Screen ? 55 : 60) : 70,
                        widthHeader: isBigScreen3 ? (isMedium3Screen ? 60 : 65) : 70,
                        minWidth: isBigScreen3 ? (isMedium3Screen ? 35 : 40) : 65,
                        minWidthHeader: isBigScreen3 ? (isMedium3Screen ? 35 : 40) : 65,
                        show: true,
                        disableGlobalFilter: checkColumnIsHidden('analysisMetaDataTime'),
                        Cell: row => renderTableCell(displayValueByAnalysisOrCamera(row, 'analysisMetaDataTime'), getAnalysisMetaDataTime(row), 'analysisMetaDataTime', false, 'Elapsed Time'),
                    },
                    {
                        Header: 'Analysis Comment',
                        accessor: 'analysiscomment',
                        widthHeader: isBigScreen3 ? (isMedium3Screen ? 190 : 200) : (isBigScreen2 ? 300 : 400),
                        width: isBigScreen3 ? (isMedium3Screen ? 180 : 200) : (isBigScreen2 ? 300 : 400),
                        minWidth: isBigScreen3 ? (isMedium3Screen ? 170 : 180) : (isBigScreen2 ? 220 : 380),
                        minWidthHeader: isBigScreen3 ? (isMedium3Screen ? 170 : 190) : (isBigScreen2 ? 220 : 380),
                        //Cell: row => renderTableCell(row.value, 'analysiscomment'),
                        show: true,
                        disableGlobalFilter: checkColumnIsHidden('analysiscomment'),
                        Cell: row => EditableCell(displayValueByAnalysisOrCamera(row, 'analysiscomment'), editRef),
                    },
                ],
            },
            {
                Header: 'Expand',
                columns: [
                    {
                        Header: 'A#',
                        id: 'A#',
                        width: isBigScreen3 ? (isMedium3Screen ? 15 : 20) : 55,
                        minWidth: isBigScreen3 ? (isMedium3Screen ? 15 : 20) : 30,
                        widthHeader: isBigScreen3 ? (isMedium3Screen ? 15 : 20) : 55,
                        minWidthHeader: isBigScreen3 ? (isMedium3Screen ? 15 : 20) : 30,
                        show: false,
                        Cell: ({ row }) =>{
                            const hasAnalysisRun = row.original.runid?.includes('.a') || 
                                row.subRows?.some(subRow => subRow.original.runid?.includes('.a'));
                            if (!hasAnalysisRun) {
                                return null;
                            }
                            return row.canExpand ? (
                                <span onClick={(e) => e.stopPropagation()}
                                    {...row.getToggleRowExpandedProps({
                                        style: {
                                            paddingLeft: `${row.depth * 2}rem`,
                                            height: 40,
                                        },
                                    })}
                                >
                                    {row.isExpanded && state.columnType === 'analysis' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                </span>
                            ) : null
                        },
                        Filter: () => (
                            <input
                                className='disabledFilter'
                                style={{
                                    border: 'none',
                                    borderBottomStyle: 'solid',
                                    borderWidth: '1px',
                                    borderBottomColor: 'rgb(118, 118, 118)',
                                    width: '100%',
                                    backgroundColor: 'white',
                                    color: 'white',
                                }}
                                disabled='disabled'
                            />
                        ),
                    },
                    {
                        id: 'expander',
                        width: isBigScreen3 ? (isMedium3Screen ? 15 : 20) : 55,
                        minWidth: isBigScreen3 ? (isMedium3Screen ? 15 : 20) : 30,
                        widthHeader: isBigScreen3 ? (isMedium3Screen ? 15 : 20) : 55,
                        minWidthHeader: isBigScreen3 ? (isMedium3Screen ? 15 : 20) : 30,
                        show: false,
                        Header: ({ getToggleAllRowsExpandedProps }) => <span {...getToggleAllRowsExpandedProps()}></span>,
                        Cell: ({ row }) =>
                            row.canExpand ? (
                                <span onClick={(e) => e.stopPropagation()}
                                    {...row.getToggleRowExpandedProps({
                                        style: {
                                            paddingLeft: `${row.depth * 2}rem`,
                                            height: 40,
                                        },
                                    })}
                                >
                                    {row.isExpanded && state.columnType === 'camera' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                </span>
                            ) : null,
                        Filter: () => (
                            <input
                                className='disabledFilter'
                                style={{
                                    border: 'none',
                                    borderBottomStyle: 'solid',
                                    borderWidth: '1px',
                                    borderBottomColor: 'rgb(118, 118, 118)',
                                    width: '100%',
                                    backgroundColor: 'white',
                                    color: 'white',
                                }}
                                disabled='disabled'
                            />
                        ),
                    },
                ],
            }            
        ];
        let isCustomer = user.roles.filter(f => f.name === 'Customer').length > 0;
        
        return isCustomer ? cols.filter(f => f.Header !== 'Expand') : cols;
    }

    const columns = React.useMemo(
        () => getTableColumns(state.user),
        [state.search_term, state.runlist, state.runlist_searched, state.columnType]
    )

    const getTitleText = text => {
        try {
            if (text !== undefined && (typeof text === 'string') || typeof text === 'number') {
                return text;
            }
            else if (text !== null && text !== undefined && text.props !== undefined && text.props.children !== undefined && text.props.children !== null) {
                return Array.isArray(text.props.children) ? text.props.children.join('') : text.props.children.toString();
            }
            else {
                return text;
            }
        }
        catch { }
    }

    const getClassName = (text, useOverflow) => {
        let classes = [(useOverflow !== undefined && useOverflow === true) ? 'truncate-overflow' : undefined];

        try {
            if (text !== null && text !== undefined && text.props !== undefined && text.props.className !== undefined && text.props.className !== undefined) {
                classes.push(text.props.className);
            }
        }
        catch { }

        return classes.join('');
    }

    const getTitleValue = title => {
        if (title !== undefined && title.props !== undefined && title.props.title !== undefined) return title.props.title;
        else return null;
    }

    const renderTableCell = (row, valueA, columnName, useOverflow = false, columnFullName) => {
        const value = typeof valueA === 'number' ?  `${valueA}`: valueA
        try {
            if (row !== undefined && row !== null && row.row.original.issubrow) return <div key={Utils.generateKey(99)} id='subrow' className={(useOverflow !== undefined && useOverflow === true) ? 'truncate-overflow' : undefined} title={columnName !== 'runstatus' ? columnFullName : null}></div>;            
            const {searchColumn} = state?.search_term.length > 0 ? state.search_term[state.search_term?.length - 1] : {searchColumn: 'all', searchWords: ''}; 
            const searchWords = state?.search_term.length > 0 ? state.search_term?.map(value => value.searchWords) : [];
            if (searchWords !== undefined && searchColumn !== undefined && searchWords.length > 0 && searchWords[0] !== undefined && value !== undefined) {
                let valueToHighlight = (columnName === searchColumn || searchColumn === 'all') ? value : '';
                if (columnName === 'runstatus' && value !== null && value.props !== undefined && value.props !== null && value.props.children !== undefined && value.props.children.props !== undefined && value.props.children.props.children !== undefined && value.props.children.props.children.length > 0 && (columnName === searchColumn || searchColumn === 'all')) {
                    valueToHighlight = Array.isArray(value.props.children.props.children) ? value.props.children.props.children.join('') : value.props.children.props.children;
                }
                else if (columnName === 'refbeadssig' && value !== null && value.props !== undefined && value.props !== null && value.props.children !== undefined && value.props.children.length > 0 && (columnName === searchColumn || searchColumn === 'all')) {
                    valueToHighlight = Array.isArray(value.props.children) ? value.props.children.join('') : value.props.children.split('.')[0];
                }
                else if (columnName === 'firsttsig' && value !== null && value.props !== undefined && value.props !== null && value.props.children !== undefined && (columnName === searchColumn || searchColumn === 'all')) {
                    valueToHighlight = Array.isArray(value.props.children) ? value.props.children.join('') : value.props.children;
                }
                else if (columnName === 'f95' && value !== null && value.props !== undefined && value.props !== null && value.props.children !== undefined && value.props.children.length > 0 && (columnName === searchColumn || searchColumn === 'all')) {
                    valueToHighlight = Array.isArray(value.props.children) ? value.props.children.join('') : value.props.children.split(',')[0];
                }
                else if (columnName === 'rlq25' && value !== null && value.props !== undefined && value.props !== null && value.props.children !== undefined && (columnName === searchColumn || searchColumn === 'all')) {
                    valueToHighlight = Array.isArray(value.props.children) ? value.props.children.join('') : value.props.children;
                }
                else if (columnName === 'ber80_200' && value !== null && value.props !== undefined && value.props !== null && value.props.children !== undefined && value.props.children.length > 0 && (columnName === searchColumn || searchColumn === 'all')) {
                    valueToHighlight = Array.isArray(value.props.children) ? value.props.children.join('') : value.props.children;
                }
                else if (columnName === 'indel_rate' && value !== null && value.props !== undefined && value.props !== null && value.props.children !== undefined && value.props.children.length > 0 && (columnName === searchColumn || searchColumn === 'all')) {
                    valueToHighlight = Array.isArray(value.props.children) ? value.props.children.join('') : value.props.children;
                }
                else if (columnName === 'aligned_bases' && value !== null && value.props !== undefined && value.props !== null && value.props.children !== undefined && value.props.children.length > 0 && (columnName === searchColumn || searchColumn === 'all')) {
                    valueToHighlight = Array.isArray(value.props.children) ? value.props.children.join('') : value.props.children;
                }
                else if (columnName === 'f95_30x_min' && value !== null && value.props !== undefined && value.props !== null && value.props.children !== undefined && (columnName === searchColumn || searchColumn === 'all')) {
                    valueToHighlight = Array.isArray(value.props.children) ? value.props.children.join('') : value.props.children;
                }
                else if (columnName === 'f95_30x_max' && value !== null && value.props !== undefined && value.props !== null && value.props.children !== undefined && (columnName === searchColumn || searchColumn === 'all')) {
                    valueToHighlight = Array.isArray(value.props.children) ? value.props.children.join('') : value.props.children;
                }
                const chunks = findAll({
                    caseSensitive: false,
                    searchWords: searchWords,
                    textToHighlight: valueToHighlight
                });

                if ((chunks !== undefined && chunks.length === 0) || valueToHighlight === null) {
                    return <div key={Utils.generateKey(99)} className={(useOverflow !== undefined && useOverflow === true) ? 'truncate-overflow' : undefined} title={columnName !== 'runstatus' ? columnFullName : null}>{value}</div>;
                }
                
                return chunks
                    .map((chunk, i) => {
                        const { end, highlight, start } = chunk;
                        const text = valueToHighlight.toString().substr(start, end - start);
                        if (highlight) {
                            let visibleColumns = Utils.getVisibleColumns();
                            //if (visibleColumns && visibleColumns.includes(columnName)) {
                                return <span className={(useOverflow !== undefined && useOverflow === true) ? 'truncate-overflow' : undefined} key={Utils.generateKey(i)} style={{ backgroundColor: 'green', boxShadow: '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)', }} title={columnName !== 'runstatus' ? columnFullName : null}>{text}</span>;
                            //}                            
                        } else {
                            return <span className={getClassName(value, useOverflow)} key={Utils.generateKey(i)} title={columnName !== 'runstatus' ? columnFullName : getTitleValue(value)} >{text}</span>;
                        }
                    });
            }
            else return <div key={Utils.generateKey(99)} className={(useOverflow !== undefined && useOverflow === true) ? 'truncate-overflow' : undefined} title={columnName !== 'runstatus' ? columnFullName : null}>{value}</div>;
        }
        catch (e) {
            //console.log(e);
        }
    }

    return (
        <Table columns={columns} data={mainTableData} handleRowClick={handleRowClick} handleCellClick={handleCellClick} selectedRowIndex={selectedRowIndex} handleClearAdvancedSearch={handleClearAdvancedSearch} expandedType={expandedType} />
    );
}

const EditableCell = ({
    value: initialValue,
    row: { index, values, original },
    column: { id, Header },
}, ref) => {
    // We need to keep and update the state of the cell normally
    const [value, setValue] = useState(initialValue);
    const [isInEditModeDetails, setEditModeDetails] = useState(false);
    const [isInEditModeAnalysisComments, setEditModeAnalysisComments] = useState(false);
    const [isEditAllowed, setEditAllowed] = useState(false);

    const dispatch = useDispatch();

    useEffect(() => {
        if (state.user !== undefined) {
            let editAllowed = checkIsEditAllowed(state.user);
            if (editAllowed && !original.issubrow) {
                setEditAllowed(true);
            }
            else setEditAllowed(false);
        }
    }, []);

    const state = useSelector(state => state);

    const onChange = e => {
        e.stopPropagation();
        e.preventDefault();
        setValue(e.target.value);
    }

    const onBlur = (e) => {
        e.stopPropagation();
        e.preventDefault();
    }

    const onClick = (e) => {
        e.stopPropagation();
        e.preventDefault();
    }

    useEffect(() => {
        setValue(initialValue);
    }, [initialValue]);

    const handleEditClick = (e, values) => {
        try {
            if (state.user !== undefined) {
                e.stopPropagation();
                e.preventDefault();

                ref.current = values.runid;

                let isEditAllowed = checkIsEditAllowed(state.user);
                if (Header === 'Details') {
                    if (values.runstatus.runstatus === "100" || (values.runstatus.isruncompleted && values.runstatus.isruncompleted === 1) || (values.runstatus.errormsg && values.runstatus.errormsg.length > 0)) {
                        dispatch(setDetailsDataSaved(false));
                        setEditModeDetails(isEditAllowed);
                        if(!state.is_in_edit_mode) dispatch(setEditMode(true));
                    }
                    else dispatch(setAlert(`Editing not supported for runs in progress.`, 'Warning', 'warning'));
                }
                else if (Header === 'Analysis Comment') {
                    dispatch(setAnalysisCommentDataSaved(false));
                    setEditModeAnalysisComments(isEditAllowed);
                    if(!state.is_in_edit_mode) dispatch(setEditMode(true));
                }
            }
        }
        catch { }
    }

    const handleCancelClick = (e, runid) => {
        try {
            e.stopPropagation();
            e.preventDefault();
            
            ref.current = runid;
            if (Header === 'Details') {
                setEditModeDetails(!isInEditModeDetails)
                setValue(initialValue);
                if(state.is_in_edit_mode) dispatch(setEditMode(false));
            } else if (Header === 'Analysis Comment') {
                setEditModeAnalysisComments(!isInEditModeAnalysisComments);
                setValue(initialValue);
                if(state.is_in_edit_mode) dispatch(setEditMode(false));
            }
        }
        catch { }
    }

    const checkIsEditAllowed = (user) => {
        let result = false;
        try {
            if (user !== undefined && user !== null && user.roles !== undefined && user.roles.length > 0 && !original.issubrow) {
                let editAllowed = user.roles.filter(f => f.name === 'AnalysisCommentEditAllowed');
                if (editAllowed && editAllowed.length) {
                    result = editAllowed.length > 0;
                }
            }
        }
        catch { }

        return result;
    }

    const handleSaveClick = (e) => {
        try {
            if (Header === 'Details') {
                e.stopPropagation();
                e.preventDefault();
                dispatch(saveDetailsData(values.runid, { data: value }, state.runlist, state.jwt));
                dispatch(setEditMode(false));
            }
            else if (Header === 'Analysis Comment') {
                e.stopPropagation();
                e.preventDefault();
                original.subRows !== undefined ? original.subRows.map(m => dispatch(saveAnalysisCommentData(m.runid, { data: value }, state.runlist, state.jwt))) : dispatch(saveAnalysisCommentData(values.runid, { data: value }, state.runlist, state.jwt));
                dispatch(setEditMode(false));
            }
        }
        catch { }
    }

    const getTitleText = text => {
        try {
            if (text !== undefined && (typeof text === 'string') || typeof text === 'number') {
                return text;
            }
            else if (text !== null && text !== undefined && text.props !== undefined && text.props.children !== undefined && text.props.children !== null) {
                return Array.isArray(text.props.children) ? text.props.children.join('') : text.props.children.toString();;
            }
            else {
                return text;
            }
        }
        catch { }
    }

    try {
        if (original && original.issubrow && (original.runid.includes('.a') && Header === 'Details')) return <div key={Utils.generateKey(99)} id='subrow'></div>;

        const {searchColumn} = state?.search_term.length > 0 ? state.search_term[state.search_term?.length - 1]: {searchColumn: 'all', searchWords: ''}; 
        const searchWords = state?.search_term.length > 0 ? state.search_term?.map(value => value.searchWords) : [];

        if (searchColumn !== undefined && searchWords !== undefined && searchWords.length > 0 && searchWords[0] !== undefined && value !== undefined && !state.is_in_edit_mode) {
            let valueToHighlight = (id === searchColumn || searchColumn === 'all') ? value : null;

            if (!valueToHighlight && !isEditAllowed) {
                <span key={Utils.generateKey(8)} title={getTitleText(value)}>{value}</span>
            }

            const chunks = findAll({
                caseSensitive: false,
                searchWords: searchWords,
                textToHighlight: valueToHighlight
            });

            let chunksAll = chunks
                .map((chunk, i) => {
                    const { end, highlight, start } = chunk;
                    if (!valueToHighlight) return <span key={Utils.generateKey(i)} title={getTitleText(value)}>{value}</span>
                    const text = valueToHighlight.toString().substr(start, end - start);
                    if (highlight) {
                        return <span key={Utils.generateKey(i)} style={{ width: '100%', backgroundColor: 'green', boxShadow: '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)', }} title={getTitleText(text)}>{text}</span>
                    } else {
                        return <span key={Utils.generateKey(i)} title={getTitleText(text)}>{text}</span>
                    }
                });

                let wrapper = (data) => {
                    return (isInEditModeAnalysisComments && !state.is_analysis_comments_data_saved && state.is_in_edit_mode) || (isInEditModeDetails && !state.is_details_data_saved && state.is_in_edit_mode) ?
                        <div className='row' key={Utils.generateKey(1)}>
                            <div className={'col-lg-10'} style={{ padding: '0 !important', margin: '0 !important' }}>
                            
                            <TextField placeholder="Click to edit" multiline rows={2} maxRows={4} 
                                    value={data !== null && data !== undefined && data.length > 0 ?
                                        data.filter(m => m !== null && m !== undefined && m.props !== undefined)
                                        .map(m => m.props)
                                        .every(e => e !== undefined) ?
                                        data.filter(m => m !== null && m !== undefined && m.props !== undefined)
                                        .map(m => m.props.children)
                                        .join('') :
                                        data :
                                        undefined} 
                                    onChange={onChange} 
                                    onClick={onClick} 
                                    onBlur={onBlur} 
                                />
                            </div>
                            <div className="col-lg-1" style={{ display: 'flex', justifyContent: 'center' }}>
                                {(isInEditModeDetails || isInEditModeAnalysisComments) && state.is_in_edit_mode ?
                                    <>
                                    <Tooltip title='Click to Save'>
                                            <span>
                                                <IconButton className="buttonsIcon" onClick={handleSaveClick} >
                                                    <SaveIcon className="buttonsIconChild" />
                                                </IconButton>
                                            </span>
                                        </Tooltip>
                                        <Tooltip title='Click to Cancel'>
                                            <span>
                                                <IconButton className="buttonsIcon" onClick={(e) => handleCancelClick(e, values.runid)} >
                                                    <CancelIcon className="buttonsIconChild" />
                                                </IconButton>
                                            </span>
                                        </Tooltip>
                                    </>
                                    :
                                    <Tooltip title='Click to Edit'>
                                        <span>
                                            <IconButton className="buttonsIcon" onClick={(e) => handleEditClick(e, values)} >
                                                <EditIcon id="filteredEditIcon" className="buttonsIconChild" />
                                            </IconButton>
                                        </span>
                                    </Tooltip>
    
                                }
                            </div>
                        </div>
                        :
                        isEditAllowed ?
                            <div className='row' key={Utils.generateKey(1)}>
                                <div className='d-flex justify-content-between align-items-center'>
                                    <div id="dataMap">
                                        {data.map(m => m)}
                                    </div>
                                    <div>
                                        <Tooltip title='Click to Edit'>
                                            <span >
                                                <IconButton onClick={(e) => handleEditClick(e, values)} className="buttonsIcon">
                                                    <EditIcon id="filteredEditIcon" className="buttonsIcon" />
                                                </IconButton>
                                            </span>
                                        </Tooltip>
                                    </div>
                                </div>
    
                            </div>
                            : <React.Fragment>{data.map(m => m)}</React.Fragment>
                            
                }
                return chunksAll !== undefined && chunksAll.length > 0 ? wrapper(chunksAll) : wrapper([value]);
            }
            else {
                return (isInEditModeAnalysisComments && !state.is_analysis_comments_data_saved && state.is_in_edit_mode && (ref.current === values.runid || ref.current === null)) || (isInEditModeDetails && !state.is_details_data_saved && state.is_in_edit_mode && (ref.current === values.runid || ref.current === null)) ?
                    <div className='row'>
                        <div className={'col-lg-10'} style={{ display: 'flex', alignItems: 'center', padding: '0 !important', margin: '0 !important' }}>
                            {/* Display the TextField and EditIcon side by side */}
                            {(!isInEditModeDetails && !isInEditModeAnalysisComments) ? (
                                <>
                                    <TextField placeholder="Click to edit" multiline rows={2} maxRows={4} value={value === null ? undefined : value} onChange={onChange} onClick={onClick} onBlur={onBlur} />
                                    <Tooltip title='Click to Edit'>
                                        <IconButton className="buttonsIcon" onClick={(e) => handleEditClick(e, values)} >
                                            <EditIcon id="filteredEditIcon" className="buttonsIconChild" />
                                        </IconButton>
                                    </Tooltip>
                                </>
                            ) : (
                                // When in edit mode, display TextField, SaveIcon, and CancelIcon
                                <>
                                    <TextField placeholder="Click to edit" multiline rows={2} maxRows={4} value={value === null ? undefined : value} onChange={onChange} onClick={onClick} onBlur={onBlur} />
                                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                                        <Tooltip title='Click to Save'>
                                            <IconButton onClick={handleSaveClick} className="buttonsIcon">
                                                <SaveIcon id="filteredEditIcon" className="buttonsIconChild" style={{ verticalAlign: 'middle' }} />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title='Click to Cancel'>
                                            <IconButton className="buttonsIcon" onClick={(e) => handleCancelClick(e, values.runid)} >
                                                <CancelIcon id="filteredEditIcon" className="buttonsIconChild" style={{ verticalAlign: 'middle' }} />
                                            </IconButton>
                                        </Tooltip>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                    :
                     isEditAllowed ?
                        <div className='row' >
                            <div className='d-flex justify-content-between align-items-center'>
                                <div>
                                    <span id="headerSpan" className={Header === 'Details' || Header === 'Analysis Comment' ? 'truncate-overflow' : ''} title={getTitleText(value)}>{value}</span>
                                </div>
                                <div id='toolDiv'>
                                    <Tooltip title='Click to Edit'>
                                        <span>
                                            <IconButton className="buttonsIcon" onClick={(e) => handleEditClick(e, values)}>
                                                <EditIcon className="buttonsIconChild" id="filteredEditIcon" style={{ float: 'right', verticalAlign: 'middle' }} />
                                            </IconButton>
                                        </span>
                                    </Tooltip>
                                </div>
                            </div>
                        </div>
                        : <span style={{ width: '100%' }} className={Header === 'Details' || Header === 'Analysis Comment' ? 'truncate-overflow' : undefined} title={getTitleText(value)}>{value}</span>
             }

    }
    catch (e) {       
        return <span></span>
    }
}

export default RenderTable;